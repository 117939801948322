import { Box, Typography } from "@mui/material";
import React, { Fragment, useLayoutEffect, useState } from "react";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  useGetUserProfileMutation,
  useGetUserTokenFBMutation,
  useGetUserTokenGoogleMutation,
} from "../../services/user";
import { homeActions } from "../../store";
import FeedbackDialog from "../../utils/FeedbackDialog";
import {
  DATA_KEY,
  ImiPathsUrls,
  legalContracts,
} from "../../utils/ImiUtilData";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementWidth } from "../../utils/ScreenManager";
import AppearanceDialog from "./AppearanceDialog";
import packageData from "../../../package.json";
import EditNameDialog from "./EditNameDialog";
import UserLoggedOutContent from "./UserLoggedOutContent";
import UserLoggedInContent from "./UserLoggedInContent";
import { useEffect } from "react";

const ImiProfile = () => {
  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const userData = useSelector((state) => state.home.userData);
  const themeMode = useSelector((state) => state.home.themeMode);
  const loginToken = useRef(null);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  //#endregion

  //#region UTIL FNS
  const refetchUserProfileAPI = () => {
    if (userToken) {
      loginToken.current = userToken;
      getUserProfile(userToken);
      setTokenAPIStateIndex(3);
    }
  };
  //#endregion

  //#region THEME DIALOG
  const [openThemeDialog, setOpenThemeDialog] = useState(false);

  const onAppearanceBtnClick = () => {
    setOpenThemeDialog(true);
  };

  const onCancelBtnClick = (newValue) => {
    setOpenThemeDialog(false);
  };

  let themeDialog = (
    <AppearanceDialog
      keepMounted
      open={openThemeDialog}
      onClose={onCancelBtnClick}
      value={themeMode}
    />
  );
  //#endregion

  //#region EDIT NAME DIALOG
  const [openEditNameDialog, setOpenEditNameDialog] = useState(false);

  const editNameDialogActionBtnClick = () => {
    setOpenEditNameDialog(false);
  };

  let editNameDialogContent = null;

  if (openEditNameDialog) {
    editNameDialogContent = (
      <EditNameDialog
        userToken={userToken}
        currentName={userData.name}
        onCloseFn={editNameDialogActionBtnClick}
        refetchUserProfileAPI={refetchUserProfileAPI}
      />
    );
  }

  //#endregion

  //#region USER PROFILE CONTENT

  const responseFacebook = (response) => {
    if (response.accessToken) {
      setTokenAPIStateIndex(1); //GET FB USER TOKEN
      getUserTokenFB(response.accessToken);
    } else {
      setOpenFeedbackDialog(true);
    }
  };

  const responseGoogle = (credentialResponse) => {
    console.log(credentialResponse);
    if (credentialResponse.credential) {
      setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
      getUserTokenGoogle(credentialResponse.credential);
    } else {
      setOpenFeedbackDialog(true);
    }
  };

  const responseGoogleError = () => {
    setOpenFeedbackDialog(true);
  };

  const signOut_BtnClick = () => {
    dispatch(homeActions.setUserToken(null));
    dispatch(homeActions.setUserData(null));

    //DELETE FROM LOCAL STORAGE
    localStorage.removeItem(DATA_KEY);
  };

  const otpBtnClick = () => {
    dispatch(
      homeActions.setIsOTPLoginDialogActive({
        state: true,
        callbackFn: null,
      })
    );
  };

  const openEditNameDialogFn = () => {
    setOpenEditNameDialog(true);
  };

  let userProfileContent;

  if (userData) {
    userProfileContent = (
      <Fragment>
        <UserLoggedInContent
          userToken={userToken}
          userData={userData}
          openEditNameDialogFn={openEditNameDialogFn}
          onAppearanceBtnClick={onAppearanceBtnClick}
          signOut_BtnClick={signOut_BtnClick}
          refetchUserProfileAPI={refetchUserProfileAPI}
        />
      </Fragment>
    );
  } else {
    userProfileContent = (
      <Fragment>
        <UserLoggedOutContent
          responseFacebook={responseFacebook}
          responseGoogle={responseGoogle}
          responseGoogleError={responseGoogleError}
          otpBtnClick={otpBtnClick}
          onAppearanceBtnClick={onAppearanceBtnClick}
        />
      </Fragment>
    );
  }

  //#endregion

  //#region USER TOKEN APIS STATES
  //USER TOKEN FB
  const [
    getUserTokenFB,
    {
      isLoading: loadingFBTokenAPI,
      isSuccess: fbTokenAPISuccess,
      data: fbTokenAPIData,
      error: fbTokenAPIError,
    },
  ] = useGetUserTokenFBMutation();

  //USER TOKEN GOOGLE
  const [
    getUserTokenGoogle,
    {
      isLoading: loadingGoogleTokenAPI,
      isSuccess: googleTokenAPISuccess,
      data: googleTokenAPIData,
      error: googleTokenAPIError,
    },
  ] = useGetUserTokenGoogleMutation();

  //USER PROFILE
  const [
    getUserProfile,
    {
      isLoading: loadingUserProfileAPI,
      isSuccess: userProfileAPISuccess,
      data: userProfileAPIData,
      error: userProfileAPIError,
    },
  ] = useGetUserProfileMutation();

  const [tokenAPIStateIndex, setTokenAPIStateIndex] = useState(0);

  let tokenAPIStateContent;

  //FB TOKEN
  if (tokenAPIStateIndex === 1) {
    if (loadingFBTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (fbTokenAPISuccess) {
      loginToken.current = fbTokenAPIData.access_token;

      getUserProfile(fbTokenAPIData.access_token);
      setTokenAPIStateIndex(3);
    } else if (fbTokenAPIError) {
      //console.log(fbTokenAPIError);

      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "FB Error",
          }}
        />
      );
    }
  }
  //GOOGLE TOKEN
  else if (tokenAPIStateIndex === 2) {
    if (loadingGoogleTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (googleTokenAPISuccess) {
      loginToken.current = googleTokenAPIData.access_token;

      getUserProfile(googleTokenAPIData.access_token);
      setTokenAPIStateIndex(3);
    } else if (googleTokenAPIError) {
      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Google Error",
          }}
        />
      );
    }
  }
  //USER TOKEN
  else if (tokenAPIStateIndex === 3) {
    if (loadingUserProfileAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (userProfileAPISuccess) {
    } else if (userProfileAPIError) {
      console.log(userProfileAPIError);
      tokenAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Login Error",
          }}
        />
      );
    }
  } else {
    tokenAPIStateContent = null;
  }
  useEffect(() => {
    if (userProfileAPISuccess) {
      // console.log(userProfileAPIData.image_url + `?${new Date()}`);
      dispatch(homeActions.setUserToken(loginToken.current));
      dispatch(
        homeActions.setUserData({
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
          id: userProfileAPIData.id,
        })
      );

      localStorage.setItem(
        DATA_KEY,
        JSON.stringify({
          token: loginToken.current,
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
          id: userProfileAPIData.id,
        })
      );

      setTokenAPIStateIndex(0);
    }
  }, [userProfileAPISuccess]);
  //#endregion

  //#region FEEDBACK DIALOG CONTENT
  const [openFeedbackDialog, setOpenFeedbackDialog] = useState(false);

  let feedbackDialogContent;

  if (openFeedbackDialog) {
    feedbackDialogContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          setOpenFeedbackDialog(false);
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    feedbackDialogContent = null;
  }

  //#endregion

  return (
    <Fragment>
      <Box
        sx={{
          justifyContent: "space-between",
          display: "flex",
          flexDirection: "column",
          minHeight: `calc(100vh - ${getElementWidth(190) + "px"})`,
        }}
      >
        <Box>
          {userProfileContent}
          {themeDialog}
          {tokenAPIStateContent}
          {feedbackDialogContent}
          {editNameDialogContent}
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: getElementWidth(25),
            }}
          >
            {packageData.version + " v"}
          </Typography>
        </Box>
      </Box>
    </Fragment>
  );
};

export default ImiProfile;
