import { Box, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import { getElementWidth } from "../../utils/ScreenManager";

export default function GameOverYelpDialog({ onCloseBtn }) {
  //#region Style
  const bottomTextStyle = {
    fontSize: getElementWidth(30),
    fontWeight: "400",
    fontFamily: "Inter",
    color: "rgba(0,0,0,255)",
  };
  //#endregion

  return (
    <Dialog
      onClose={onCloseBtn}
      //fullScreen
      fullWidth={true}
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "transparent",
          backgroundImage: "none",
          boxShadow:'none'
        },
      }}
    >
      <Box
        sx={{
          background: `url("/images/gameOverYelpDialogImg.png")`,
          backgroundRepeat: "no-repeat",
          whiteSpace: "nowrap",
          backgroundSize: "contain",
          backgroundPosition: "center center",
          height: "60vh",
          width: "auto",
        }}
      >
        {/* <img
          alt="gameOver"
          src="/gameOverYelpDialogImg.png"
          height={"100%"}
          width={"auto"}
        /> */}
      </Box>
      {/* <Box
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: "#FFFFFF",
          boxShadow: " 0px -5px 25px 7px rgba(0, 0, 0, 0.13)",
          height: "50vh",
          flexDirection: "column",
        }}
      >
        <Box
          sx={{
            height: "3vh",
            //backgroundColor: "red",
            width: "100%",
          }}
        ></Box>
        <Box
          sx={{
            height: "20vh",
            //backgroundColor: "yellow",
            width: "100%",
            justifyContent: "center",
            display: "flex",
          }}
        >
          <img
            width={getElementWidth(300)}
            height="100%"
            src="https://static.vecteezy.com/system/resources/previews/010/870/984/original/3d-character-illustration-of-casual-man-holding-a-pink-gift-with-his-left-hand-while-waving-say-hi-valentine-s-day-concept-3d-character-illustration-free-png.png"
            alt="man-cartoon"
          />
        </Box>
        <Box
          sx={{
            height: "2vh",
            //backgroundColor: "red",
            width: "100%",
          }}
        ></Box>
        <Box
          sx={{
            height: "5vh",
            //backgroundColor: "yellow",
            width: "100%",
            justifyContent: "center",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography
            style={{
              fontSize: getElementWidth(35),
              fontWeight: "700",
              fontFamily: "Inter",
              color: "rgba(206,60,54,255)",
            }}
          >
            Congratulations!
          </Typography>
        </Box>
        <Box
          sx={{
            height: "20vh",
            //backgroundColor: "red",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "100%",
              hight: "100%",
              paddingLeft: getElementWidth(10),
              paddingRight: getElementWidth(10),
              //backgroundColor: "yellow",
              textAlign: "center",
            }}
          >
            <span style={bottomTextStyle}>Hurry up and scream your</span>
            <br />
            <span style={bottomTextStyle}>way to the gift counter to</span>
            <br />
            <span style={bottomTextStyle}>collect the gift.</span>
          </Box>
        </Box>
      </Box> */}
    </Dialog>
  );
}
