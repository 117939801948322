import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";

export default function PromoGameListItem({ item, goHomeToOpenGameFn }) {
  console.log(item);

  //#region GET DATE FN
  const getEndDate = () => {
    var dateData = new Date(item.endDate)
      .toLocaleString("default", {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
        //hour12: true,
        hourCycle: "h12",
      })
      .split(" ");
    return `Ends on ${dateData[1].replace(",", "")} ${dateData[0]}, ${dateData[2]} ${dateData[3]}`;
  };
  //#endregion

  return (
    <Box sx={{ marginRight: getElementWidth(3), marginLeft: getElementWidth(3), height: getElementHeight(130), padding: "0" }}>
      <Box
        sx={{
          height: getElementHeight(130),
          borderRadius: "1.1vh",
          boxShadow: "0px 0px 7px rgba(0, 0, 0, 0.1)",
          paddingLeft: getElementWidth(5),
          paddingRight: getElementWidth(5),
          paddingTop: getElementWidth(2),
          paddingBottom: getElementWidth(2),
          width: "100%",
        }}
        onClick={() => {
          goHomeToOpenGameFn(item.gameUUID);
        }}
      >
        <Grid container flexDirection={"row"} justifyContent={"space-between"}>
          <Grid item>
            <Grid container flexDirection={"column"} justifyContent="flex-start">
              <Grid item>
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 1)",
                    fontSize: getElementWidth(36),
                    fontWeight: "400",
                    fontFamily: "Roboto",
                  }}
                  noWrap={true}
                  textOverflow={"ellipsis"}
                >
                  {item.gameName}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  sx={{
                    color: "rgba(0, 0, 0, 1)",
                    fontSize: getElementWidth(28),
                    fontWeight: "400",
                    fontFamily: "Roboto",
                  }}
                >
                  {getEndDate()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <IconButton
              edge="end"
              sx={{
                color: "#CD3C36",
                fontSize: getElementWidth(20),
              }}
              onClick={() => {
                //goHomeToOpenGameFn(item.gameUUID);
              }}
            >
              <PlayArrowIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
