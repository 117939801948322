import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const gameApi = createApi({
  reducerPath: "gameApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getGameDetails: builder.mutation({
      query: ({ uuid }) => ({
        url: `/lgo/game/${uuid}`,
        method: "GET",
        headers: {
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getPracticeGameDetails: builder.mutation({
      query: ({ gameUUID }) => ({
        url: `/lgo/practice-game/${gameUUID}`,
        method: "GET",
        headers: {
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getGameIntroDetails: builder.query({
      query: ({ gameUUID }) => ({
        url: `/lgo/game/intro-info/${gameUUID}`,
        method: "GET",
        headers: {
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getAllPromoGameDetails: builder.query({
      query: ({ gameUUID }) => ({
        url: `/lgo/promo/game-leaderboard/${gameUUID}?size=5&page=0`,
        method: "GET",
        headers: {
          "x-platform": "vodafone-fiji",
        },
      }),
      //transformResponse: (response) => response?.data,
    }),
  }),
});

export const { useGetGameDetailsMutation, useGetPracticeGameDetailsMutation, useGetGameIntroDetailsQuery, useGetAllPromoGameDetailsQuery } = gameApi;
export default gameApi;
