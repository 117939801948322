import { ArrowBackIos } from "@mui/icons-material";
import { AppBar, Backdrop, Box, CircularProgress, Dialog, Grid, IconButton, Toolbar, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetAllPromoGameDetailsQuery } from "../../../services/game";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import { ImiPathsUrls } from "../../../utils/ImiUtilData";
import { getElementWidth } from "../../../utils/ScreenManager";
import PromoGameListItem from "./PromoGameListItem";

const PromoGameList = ({ onCloseFn }) => {
  //#region  HOOKS
  const uuid = useSelector((state) => state.home.activeGameUUID);
  const navigate = useNavigate();

  //#endregion

  //#region APIS
  const { data: getAllPromoGameDetailsResponse, error: getAllPromoGameDetailsError, isLoading: isLoadingGetAllPromoGameDetailsAPI } = useGetAllPromoGameDetailsQuery({ gameUUID: uuid });
  //#endregion

  //#region UTIL FNS

  const goHomeToOpenGameFn = (uuid) => {
    onCloseFn();
    navigate(ImiPathsUrls.home, {
      replace: true,
      state: { gameId: uuid },
    });
  };
  //#endregion

  //#region SHOW CHALLENGE API STATES
  let getAllPromoGameDetailsAPIContent;
  if (isLoadingGetAllPromoGameDetailsAPI) {
    getAllPromoGameDetailsAPIContent = (
      <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
    );
  } else if (getAllPromoGameDetailsError) {
    console.log(getAllPromoGameDetailsError);
    getAllPromoGameDetailsAPIContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          onCloseFn();
        }}
        data={{
          title: "Oops..!",
          msg: getAllPromoGameDetailsError.data?.errorMessage ?? "Something Went Wrong",
        }}
      />
    );
  } else if (getAllPromoGameDetailsResponse) {
    if (getAllPromoGameDetailsResponse.dataRows.length > 0) {
      getAllPromoGameDetailsAPIContent = (
        <Grid justifyContent={"center"} flexDirection="column" spacing={3} container>
          {getAllPromoGameDetailsResponse.dataRows.map((item, index) => (
            <Grid item key={index}>
              <PromoGameListItem item={item} goHomeToOpenGameFn={goHomeToOpenGameFn} />
            </Grid>
          ))}
        </Grid>
      );
    } else {
      getAllPromoGameDetailsAPIContent = (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            minHeight: `calc(100vh - ${getElementWidth(450) + "px"})`,
          }}
        >
          <Typography
            sx={{
              fontSize: getElementWidth(40),
              fontWeight: "700",
              fontFamily: "Inter",
              whiteSpace: "pre-line",
              color: "black",
            }}
          >
            {`Sorry, there are no active\ncompetitions at the moment.\n\nAwait one soon!`}
          </Typography>
        </Box>
      );
    }
  }
  //#endregion
  return (
    <Dialog
      fullScreen
      open={true}
      fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: "#FFFFFF",
        },
      }}
    >
      <AppBar elevation={0} position="sticky" sx={{ backgroundColor: "#FFFFFF", paddingTop: getElementWidth(5) }}>
        <Toolbar>
          <IconButton
            edge="start"
            sx={{
              color: "rgba(17, 17, 17, 1)",
            }}
            onClick={() => {
              onCloseFn();
            }}
          >
            <ArrowBackIos />
          </IconButton>
          <Typography
            component="div"
            sx={{
              flexGrow: 1,
              color: "rgba(0, 0, 0, 0.87)",
              fontSize: getElementWidth(40),
              fontWeight: "400",
              fontFamily: "Roboto",
            }}
          >
            Competitions to enter
          </Typography>
        </Toolbar>
      </AppBar>
      <Box sx={{ minHeight: "3vh", width: "100%" }}></Box>
      <Box>{getAllPromoGameDetailsAPIContent}</Box>
    </Dialog>
  );
};

export default PromoGameList;
