import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";

export const getShareLinkFn = (linkData) => {
  return `${window.location.origin}/home?gameId=${linkData.gameUUID}&friendChallengeCode=${linkData.duelToken}`;
};

const P2PDuelShareDialog = ({ linkData, onCloseFn }) => {
  //#region HOOKS

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ state: false, errorMsg: null });

  const isIos = useSelector((state) => state.home.isIOSDevice);
  const isInFirefoxBrowser = useSelector((state) => state.home.isInFirefox);

  useEffect(() => {
    if (isInFirefoxBrowser) {
      setError({ state: true, errorMsg: "Browser does not support this feature" });
      return;
    }

    handleShare();
  }, []);
  //#endregion

  //#region UTIL FNS

  const handleShare = async () => {
    let shareLink = getShareLinkFn(linkData);

    let shareData;
    if (isIos) {
      shareData = {
        text: shareLink,
      };
    } else {
      shareData = {
        title: "share",
        text: shareLink,
      };
    }

    try {
      if (!navigator.canShare(shareData)) {
        setError({ state: true, errorMsg: "Something Went Wrong...!" });
        return;
      }
      setLoading(true);
      await navigator
        .share(shareData)
        .then(() => {
          onCloseFn();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError({ state: true, errorMsg: "Something Went Wrong...!" });
        });
    } catch (err) {
      setLoading(false);
      setError({ state: true, errorMsg: "Something Went Wrong...!" });
    }
  };
  //#endregion

  return (
    <Fragment>
      {loading && <LoadingDialog />}
      {error.state && (
        <FeedbackDialog
          open={true}
          onClose={() => {
            onCloseFn();
          }}
          data={{
            title: "Oops!",
            msg: error.errorMsg,
          }}
        ></FeedbackDialog>
      )}
    </Fragment>
  );
};

export default P2PDuelShareDialog;
