import { Close, PhoneAndroidRounded } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useConfirmOTPLoginMutation, useGetUserProfileMutation, useInitiateOTPLoginMutation } from "../../services/user";
import { homeActions } from "../../store";
import FeedbackDialog from "../FeedbackDialog";
import { COMMON_DATA, DATA_KEY } from "../ImiUtilData";
import LoadingDialog from "../LoadingDialog";
import { getElementWidth } from "../ScreenManager";

const OTPLoginDialog = ({ closeDialogBoxFn }) => {
  //#region APIS
  //INITIATE OTP LOGIN API
  const [initiateOTPLogin, { isLoading: isLoadingInitiateOTPLoginAPI, isSuccess: isSuccessInitiateOTPLoginAPI, data: initiateOTPLoginAPIData, error: initiateOTPLoginAPIError }] = useInitiateOTPLoginMutation();

  //CONFIRM OTP LOGIN API
  const [confirmOTPLogin, { isLoading: isLoadingConfirmOTPLoginAPI, isSuccess: isSuccessConfirmOTPLoginAPI, data: confirmOTPLoginAPIData, error: confirmOTPLoginAPIError }] = useConfirmOTPLoginMutation();

  //USER PROFILE
  const [getUserProfile, { isLoading: loadingUserProfileAPI, isSuccess: userProfileAPISuccess, data: userProfileAPIData, error: userProfileAPIError }] = useGetUserProfileMutation();
  //#endregion

  //#region HOOKS

  const dispatch = useDispatch();

  const [contentStateIndex, setContentStateIndex] = useState({ index: 0, data: null });

  const [phoneNumber, setPhoneNumber] = useState({
    number: "",
    error: false,
    disable: false,
  });

  const [otp, setOTP] = useState({
    otp: "",
    error: false,
    disable: true,
  });

  useEffect(() => {
    if (userProfileAPISuccess) {
      dispatch(homeActions.setUserToken(contentStateIndex.data));
      dispatch(
        homeActions.setUserData({
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
          id: userProfileAPIData.id,
        })
      );

      localStorage.setItem(
        DATA_KEY,
        JSON.stringify({
          token: contentStateIndex.data,
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
          id: userProfileAPIData.id,
        })
      );
      closeFn();
    }
  }, [userProfileAPISuccess]);
  //#endregion

  //#region UTIL FNS
  const closeFn = (state) => {
    setContentStateIndex({ index: 0, data: null });
    closeDialogBoxFn();
  };
  //#endregion

  //#region ENTER PHONE NUM DIALOG
  const onPhoneNumChanged = (event) => {
    let num = event.target.value;

    if (num.length === 0) {
      setPhoneNumber({
        number: "",
        error: false,
        disable: true,
      });
      return;
    }

    let fijiPhoneNumLength = COMMON_DATA.fijiData.phoneNumLength;

    if (num.length > fijiPhoneNumLength) {
      return;
    }

    let disableSubmitBtn = num.length < fijiPhoneNumLength;

    setPhoneNumber({
      number: num,
      error: false,
      disable: disableSubmitBtn,
    });
  };

  const phoneNumCloseButtonHandler = () => {
    setPhoneNumber({
      number: "",
      error: false,
      disable: true,
    });

    //onClose();
  };

  let phoneNumDialogContent = (
    <Dialog fullWidth={true} open={true}>
      <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
        <Typography
          style={{
            fontSize: getElementWidth(35),
            textAlign: "left",
            fontWeight: "100",
          }}
        >
          Login to continue
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ padding: getElementWidth(10) }}>
        <Typography
          component={"span"}
          style={{
            fontSize: getElementWidth(28),
            marginLeft: getElementWidth(10),
            textAlign: "left",
            fontWeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          Input your vodafone number
        </Typography>
        <TextField
          style={{
            width: "100%",
            borderRadius: "0",
            height: getElementWidth(100),
            fontSize: getElementWidth(30),
            marginBottom: "40px",
            padding: "0px",
          }}
          type="number"
          error={phoneNumber.error}
          placeholder="XXXXXXX"
          variant="outlined"
          value={phoneNumber.number}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneAndroidRounded />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="close" onClick={phoneNumCloseButtonHandler} style={{ padding: "0px" }}>
                  <Close />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onChange={onPhoneNumChanged}
        />
        <div style={{ height: getElementWidth(50) }} />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              initiateOTPLogin({ phoneNum: `${COMMON_DATA.fijiData.zipCode}${phoneNumber.number}` });
              setContentStateIndex({ index: 1, data: null });
            }}
            style={{
              fontSize: getElementWidth(17),
              textTransform: "none",
              alignSelf: "center",
              marginBottom: "10px",
            }}
            disableElevation
            disabled={phoneNumber.disable}
          >
            Submit
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeFn();
          }}
          color="primary"
        >
          <Typography
            style={{
              textTransform: "none",
              fontSize: getElementWidth(30),
              textAlign: "left",
            }}
          >
            Cancel
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );

  //#endregion

  //#region ENTER OTP DIALOG
  const onOTPChanged = (event) => {
    //console.log(telcoList.current[0].regex);
    let otpValue = event.target.value;

    if (otpValue.length === 0) {
      setOTP({
        otp: otpValue,
        error: false,
        disable: true,
      });
      return;
    }

    if (otpValue.length > 6) {
      return;
    }

    let regex = new RegExp("^[0-9]{6}$");

    if (otpValue.match(regex)) {
      setOTP({
        otp: otpValue,
        error: false,
        disable: false,
      });
      return;
    }

    setOTP({
      otp: otpValue,
      error: true,
      disable: true,
    });

    //onSearch(event.target.value);
  };

  let otpDialogContent = (
    <Dialog fullWidth={true} open={true}>
      <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
        <Typography
          style={{
            fontSize: getElementWidth(35),
            textAlign: "left",
            fontWeight: "100",
          }}
        >
          Enter OTP
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ padding: getElementWidth(10) }}>
        <TextField
          style={{
            width: "100%",
            borderRadius: "0",
            height: getElementWidth(100),
            fontSize: getElementWidth(30),
            marginBottom: "40px",
            padding: "0px",
          }}
          type="number"
          error={otp.error}
          placeholder="123456"
          variant="outlined"
          value={otp.otp}
          onChange={onOTPChanged}
          helperText={
            <Typography
              component={"span"}
              style={{
                fontSize: getElementWidth(18),
                textAlign: "left",
                fontWeight: "normal",
                whiteSpace: "pre-line",
              }}
            >
              {`Please type the verification code sent to ${phoneNumber.number}`}
            </Typography>
          }
        />
        {/* <div style={{ height: getElementWidth(50) }} /> */}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              confirmOTPLogin({ otp: otp.otp, refID: contentStateIndex.data });
              setContentStateIndex({ index: 3, data: null });
            }}
            style={{
              fontSize: getElementWidth(17),
              textTransform: "none",
              alignSelf: "center",
              marginBottom: "10px",
            }}
            disableElevation
            disabled={otp.disable}
          >
            Submit
          </Button>
        </div>
        <Typography
          style={{
            fontSize: getElementWidth(17),
            textAlign: "center",
            fontWeight: "normal",
            lineHeight: "normal",
            whiteSpace: "pre-line",
          }}
        >
          {}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            closeFn();
          }}
          color="primary"
        >
          <Typography
            style={{
              textTransform: "none",
              fontSize: getElementWidth(30),
              textAlign: "left",
            }}
          >
            Cancel
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );

  //#endregion

  //#region CONTENT STATES
  let stateContent;
  //PHONE NUM DIALOG
  if (contentStateIndex.index === 0) {
    stateContent = phoneNumDialogContent;
    //INITIATE OTP API
  } else if (contentStateIndex.index === 1) {
    if (isLoadingInitiateOTPLoginAPI) {
      stateContent = <LoadingDialog />;
    } else if (initiateOTPLoginAPIError) {
      stateContent = (
        <FeedbackDialog
          open={true}
          onClose={closeFn}
          data={{
            title: "Oops!",
            msg: initiateOTPLoginAPIError.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    } else if (isSuccessInitiateOTPLoginAPI) {
      console.log(initiateOTPLoginAPIData);
      setContentStateIndex({ index: 2, data: initiateOTPLoginAPIData.reference_id });
    }
    //OTP DIALOG
  } else if (contentStateIndex.index === 2) {
    stateContent = otpDialogContent;
  }
  //OTP CONFIRM DIALOG
  else if (contentStateIndex.index === 3) {
    if (isLoadingConfirmOTPLoginAPI) {
      stateContent = <LoadingDialog />;
    } else if (confirmOTPLoginAPIError) {
      stateContent = (
        <FeedbackDialog
          open={true}
          onClose={closeFn}
          data={{
            title: "Oops!",
            msg: confirmOTPLoginAPIError.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    } else if (isSuccessConfirmOTPLoginAPI) {
      console.log(confirmOTPLoginAPIData);
      getUserProfile(confirmOTPLoginAPIData.access_token);
      setContentStateIndex({ index: 4, data: confirmOTPLoginAPIData.access_token });
    }
  }
  //USER PROFILE API
  else if (contentStateIndex.index === 4) {
    if (loadingUserProfileAPI) {
      stateContent = <LoadingDialog />;
    } else if (userProfileAPIError) {
      console.log(userProfileAPIError);
      stateContent = (
        <FeedbackDialog
          open={true}
          onClose={closeFn}
          data={{
            title: "Oops!",
            msg: userProfileAPIError.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  return <div>{stateContent}</div>;
};

export default OTPLoginDialog;
