import { grey } from "@mui/material/colors";
import { useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useWindowSize } from "./hooks/useWindowSize";
import { DATA_KEY, GameModes, ImiPathsUrls, IMIThemeMode, isUnderMaintenance, PromoGameLeaderBoardStatus, THEME_DATA, webSocketUrl } from "./utils/ImiUtilData";
import { Navigate, Route, Routes } from "react-router-dom";
import ImiAppBars from "./components/AppBars";
import { screenSizeData } from "./utils/ScreenManager";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import ImiHome from "./components/Home";
import ImiProfile from "./components/Profile";
import ImiGameFrame from "./components/Game/ImiGameFrame";
import ImiSearch from "./components/Search";
import PageNotFound from "./utils/PageNotFound";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { homeActions } from "./store";
import useWebSocket from "react-use-websocket";
import { createContext } from "react";
import ImiDuels from "./components/Duels";
import PageUnderConstruction from "./utils/PageUnderConstruction";

export const ThemeColors = {
  backgroundColor: {
    light: grey[300],
    //dark : 'rgba(0, 0, 0, 0.54)'
    dark: "rgba(0, 0, 0, 255)",
  },
  appBarColor: {
    light: "rgba(255,255,255,255)",
    dark: "rgba(33,33,33,255)",
  },
  primaryTextColor: {
    light: "rgba(0, 0, 0, 255)",
    dark: "rgba(255,255,255,255)",
  },
  secondaryTextColor: {
    light: grey[700],
    dark: grey[500],
  },
  bottomNavColor: {
    light: "rgba(255,255,255,255)",
    //dark : grey[800]
    dark: "rgba(48,48,48,255)",
  },
  bottomNavSelectColor: {
    light: "rgba(206, 60, 54,255)",
    dark: "rgba(206, 60, 54,255)",
  },
  backDropColor: {
    light: "rgba(0,0,0,255)",
    dark: "rgba(33,33,33,100)",
  },
};

const ReadyStates = {
  CONNECTING: 0,
  OPEN: 1,
  CLOSING: 2,
  CLOSED: 3,
};

export const commonObjContext = createContext();

function App() {
  //#region HOOKS & APIS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const userToken = useSelector((state) => state.home.userToken);
  const themeMode = useSelector((state) => state.home.themeMode);
  const iframeRef = useRef(null);

  const { sendMessage, lastMessage, readyState } = useWebSocket(webSocketUrl, {
    share: true,
    shouldReconnect: () => true,
  });

  useEffect(() => {
    if (lastMessage) {
      let dataObj = JSON.parse(lastMessage.data);
      //console.log(dataObj);

      if (dataObj.type === "GAME_LEADERBOARD_START" || dataObj.type === "GAME_LEADERBOARD_END") {
        let data = dataObj.data;

        if (data.game_type === GameModes.SPOT) {
          dispatch(homeActions.setSocketData({ type: dataObj.type, location: location.pathname, data: data }));
        }
      }
    }
  }, [lastMessage]);

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    //localStorage.removeItem(DATA_KEY);

    if (userToken === null) {
      let _userData = localStorage.getItem(DATA_KEY);

      if (_userData) {
        _userData = JSON.parse(_userData);

        dispatch(homeActions.setUserToken(_userData.token));
        dispatch(
          homeActions.setUserData({
            name: _userData.name,
            image: _userData.image,
            id: _userData.id,
          })
        );
      }

      let _themeData = localStorage.getItem(THEME_DATA);

      if (_themeData) {
        dispatch(homeActions.changeTheme(_themeData));
      }
    }

    if (readyState === ReadyStates.OPEN) {
      console.log("SOCKET CONNECTED");
      if (userToken) {
        let decodedObj = parseJwt(userToken);

        sendMessage(
          JSON.stringify({
            type: "REGISTER_USER",
            country: "FIJI",
            data: {
              user_uuid: decodedObj.ud,
            },
          })
        );
      }
    }
  }, [readyState]);

  //SETTING DOMAIN FOR BRIDGE COMMUNICATION
  useEffect(() => {
    // const url = new URL(window.location);
    // const urlParts = new URL(url).hostname.split(".");
    // const domain = urlParts.slice(-2).join(".");
    // document.domain = domain;

    if (isIOSDevice()) {
      dispatch(homeActions.setIsIOSDevice(true));
    }

    if (isInFirefoxBrowser()) {
      dispatch(homeActions.setIsInFirefox(true));
    }
  }, []);

  //#endregion

  //#region DECODE JWT TOKEN
  function parseJwt(token) {
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );

    return JSON.parse(jsonPayload);
  }
  //#endregion

  //#region THEME DATA
  [screenSizeData.currentScreenWidth, screenSizeData.currentScreenHeight] = useWindowSize();

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: themeMode,
          primary: {
            main: "rgba(206, 60, 54,255)",
            light: "rgba(206, 60, 54,255)",
            dark: "rgba(206, 60, 54,255)",
          },
          secondary: {
            main: "rgba(206, 60, 54,255)",
          },
          icon: {
            main: themeMode === IMIThemeMode.LIGHT ? ThemeColors.primaryTextColor.light : ThemeColors.primaryTextColor.dark,
          },
          background: {
            default: themeMode === IMIThemeMode.LIGHT ? ThemeColors.backgroundColor.light : ThemeColors.backgroundColor.dark,
            paper: themeMode === IMIThemeMode.LIGHT ? ThemeColors.backgroundColor.light : ThemeColors.backgroundColor.dark,
          },
          text: {
            primary: themeMode === IMIThemeMode.LIGHT ? ThemeColors.primaryTextColor.light : ThemeColors.primaryTextColor.dark,
            secondary: themeMode === IMIThemeMode.LIGHT ? ThemeColors.secondaryTextColor.light : ThemeColors.secondaryTextColor.dark,
            neutral: "rgba(255, 0, 0,255)",
          },
        },
        components: {
          MuiAppBar: {
            styleOverrides: {
              colorPrimary: {
                backgroundColor: themeMode === IMIThemeMode.LIGHT ? ThemeColors.appBarColor.light : ThemeColors.appBarColor.dark,
              },
            },
          },
          MuiBottomNavigation: {
            styleOverrides: {
              root: {
                backgroundColor: themeMode === IMIThemeMode.LIGHT ? ThemeColors.bottomNavColor.light : ThemeColors.bottomNavColor.dark,
              },
            },
          },
          MuiBottomNavigationAction: {
            styleOverrides: {
              root: {
                "&.Mui-selected": {
                  color: themeMode === IMIThemeMode.LIGHT ? ThemeColors.bottomNavSelectColor.light : ThemeColors.bottomNavSelectColor.dark,
                },
              },
            },
          },
        },
      }),
    [themeMode]
  );

  //#endregion

  //#region UTIL FNS

  const openGameFn = (
    url,
    uuid = null,
    isGame = false,
    gameMode = {
      type: GameModes.NORMAL,
      associatedPromoGameID: null,
      showPromoIntroScreen: false,
      promoGameLeaderBoardStatus: PromoGameLeaderBoardStatus.notAvailable,
      friendChallengeCode: null,
    },
    justSubscribedBool = false
  ) => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: isGame,
        url: url,
        justSubscribedBool: justSubscribedBool,
      })
    );

    if (uuid) {
      dispatch(homeActions.setActiveGameUUID(uuid));
    }

    dispatch(homeActions.setActiveGameMode(gameMode));

    //---///
    let openGameBool = false;

    if (gameMode.type === GameModes.NORMAL || gameMode.type === GameModes.PRACTICE) {
      openGameBool = true;
    } else if ((gameMode.type === GameModes.SPOT || gameMode.type === GameModes.COMPETITION) && !gameMode.showPromoIntroScreen) {
      openGameBool = true;
    }

    if (openGameBool) {
      navigate(ImiPathsUrls.game, {
        state: {
          from: location,
        },
      });
    }
    //---///
  };

  const isIOSDevice = () => {
    var userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return true;
    }
    return false;
  };

  const isInFirefoxBrowser = () => {
    return navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  };

  const commonObj = {
    openGameFn: openGameFn,
    iframeRef: iframeRef,
  };

  const getRoutes = () => {
    if (isUnderMaintenance) {
      return (
        <Routes>
          <Route path="/" element={<Navigate to={ImiPathsUrls.home} />} />
          <Route path={ImiPathsUrls.home} element={<PageUnderConstruction />} />
          <Route path="*" element={<PageUnderConstruction />} />
        </Routes>
      );
    } else {
      return (
        <Routes>
          <Route path="/" element={<Navigate to={ImiPathsUrls.home} />} />
          <Route path={ImiPathsUrls.home} element={<ImiHome openGameFn={openGameFn} />} />
          <Route path={ImiPathsUrls.search} element={<ImiSearch openGameFn={openGameFn} />} />
          <Route path={ImiPathsUrls.challenges} element={<ImiDuels openGameFn={openGameFn} />} />
          <Route path={ImiPathsUrls.profile} element={<ImiProfile />} />
          <Route path={ImiPathsUrls.game} element={<ImiGameFrame />} />
          <Route path={ImiPathsUrls.maintenance} element={<PageUnderConstruction />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      );
    }
  };
  //#endregion

  return (
    <ThemeProvider theme={theme}>
      <commonObjContext.Provider value={commonObj}>
        <ImiAppBars>{getRoutes()}</ImiAppBars>
      </commonObjContext.Provider>
    </ThemeProvider>
  );
}

export default App;
