import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  Typography,
} from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useGetGameIntroDetailsQuery } from "../../services/game";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { getElementWidth } from "../../utils/ScreenManager";
import { useRef } from "react";

export default function PromoGameIntroScreen({ loadGameScreen, gameUUID }) {
  //#region  API && HOOKS
  const [selectedItemIndex, setSelectedItemIndex] = useState(0);
  const [touchPosition, setTouchPosition] = useState(null);
  //#endregion

  //#region LOAD GAME SCREEN FN
  const loadGameScreenFn = () => {
    loadGameScreen();
  };
  //#endregion

  //#region SLIDE 1
  const GetSlideOneContent = (titleUrl, description) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            background: `url(${titleUrl})`,
            backgroundRepeat: "no-repeat",
            whiteSpace: "nowrap",
            backgroundSize: "contain",
            backgroundPosition: "center center",
            height: "20vh",
            width: "auto",
          }}
        ></Box>
        <Box
          sx={{
            height: "10vh",
          }}
        ></Box>
        <Box sx={{ height: "30vh" }}>
          <Typography
            style={{
              display: "block",
              whiteSpace: "pre",
              fontSize: getElementWidth(40),
              fontWeight: "700",
              fontFamily: "Inter",
              color: "rgba(255,255,255,255)",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {description}
          </Typography>
        </Box>
      </div>
    );
  };

  //#endregion

  //#region SLIDE 2
  const GetSlideTwoContent = (descriptionTwo) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            height: "25vh",
            //backgroundColor: "yellow",
          }}
        ></Box>

        <Box
          sx={{
            height: "35vh",
            //backgroundColor: "yellow",
          }}
        >
          <Typography
            component={"span"}
            style={{
              fontSize: getElementWidth(40),
              fontWeight: "700",
              fontFamily: "Inter",
              whiteSpace: "pre-line",
              color: "rgba(255,255,255,255)",
            }}
          >
            {descriptionTwo}
          </Typography>
        </Box>
      </div>
    );
  };

  //#endregion

  //#region SLIDE 3

  const GetSlideThreeContent = (descriptionThree) => {
    let slide3DesArray = descriptionThree.split("#");

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            height: "20vh",
          }}
        ></Box>

        <Box
          sx={{
            height: "25vh",
          }}
        >
          <Typography
            component={"span"}
            style={{
              fontSize: getElementWidth(40),
              fontWeight: "700",
              fontFamily: "Inter",
              color: "rgba(255,255,255,255)",
              whiteSpace: "pre-line",
            }}
          >
            {slide3DesArray[0] ?? ""}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "15vh",
          }}
        >
          <Typography
            component={"span"}
            style={{
              fontSize: getElementWidth(30),
              fontWeight: "300",
              fontFamily: "Inter",
              color: "rgba(255,255,255,255)",
              whiteSpace: "pre-line",
            }}
          >
            {slide3DesArray[1] ?? ""}
          </Typography>
        </Box>
      </div>
    );
  };
  //#endregion

  //#region UTIL FNS
  const handleTouchStart = (e) => {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  };

  const handleClick = (index) => {
    setSelectedItemIndex(index);
  };

  const handleTouchMove = (e) => {
    const touchDown = touchPosition;

    if (touchDown === null) {
      return;
    }

    const currentTouch = e.touches[0].clientX;
    const diff = touchDown - currentTouch;

    if (diff > 5) {
      handleClick(selectedItemIndex === 2 ? 0 : selectedItemIndex + 1);
    }

    if (diff < -5) {
      handleClick(selectedItemIndex === 0 ? 2 : selectedItemIndex - 1);
    }

    setTouchPosition(null);
  };

  //#endregion

  //#region INTRO API
  const {
    isLoading: isLoadingIntroAPI,
    isSuccess: isSuccessIntroAPI,
    data: introAPIData,
    error: introAPIError,
  } = useGetGameIntroDetailsQuery({ gameUUID });
  //#endregion

  //#region INTRO API STATES
  let introAPiContent;
  if (isLoadingIntroAPI) {
    introAPiContent = (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (introAPIError) {
    introAPiContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops..!",
          msg: introAPIError.data?.errorMessage ?? "Something Went Wrong",
        }}
      />
    );
  } else if (isSuccessIntroAPI) {
    introAPiContent = (
      <Dialog
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        fullScreen
        open={true}
        PaperProps={{
          style: {
            whiteSpace: "nowrap",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "center center",
            height: "100vh",
            width: getElementWidth(720),
            backgroundImage: `url(${
              selectedItemIndex === 0
                ? introAPIData.bg_images.image_one
                : selectedItemIndex === 1
                ? introAPIData.bg_images.image_one
                : introAPIData.bg_images.image_one
            })`,
          },
        }}
      >
        <Box
          sx={{
            height: "20vh",
          }}
        />
        {selectedItemIndex === 0
          ? GetSlideOneContent(
              introAPIData.title_url,
              introAPIData.descriptions.description_one
            )
          : selectedItemIndex === 1
          ? GetSlideTwoContent(introAPIData.descriptions.description_two)
          : GetSlideThreeContent(introAPIData.descriptions.description_three)}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              height: "5vh",
            }}
          >
            {[1, 2, 3].map((data, i) => (
              <Box
                key={i}
                onClick={() => handleClick(i)}
                style={{
                  border: "1px solid white",
                  width: "10px",
                  borderRadius: "50%",
                  height: "10px",
                  backgroundColor:
                    selectedItemIndex === i ? "white" : "transparent",
                  marginRight: "8px",
                }}
              ></Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "right",
              alignItems: "flex-start",
              paddingRight: getElementWidth(5),
              paddingTop: getElementWidth(2),
              height: "15vh",
            }}
          >
            <Button
              sx={{
                backgroundColor: "rgb(211,47,47)",
                color: "white",
                borderRadius: "4px",
                minWidth: getElementWidth(200),
              }}
              onClick={loadGameScreenFn}
            >
              Skip
            </Button>
          </Box>
        </Box>
      </Dialog>
    );
  }
  //#endregion

  useEffect(() => {
    const interval = setInterval(() => {
      handleClick(selectedItemIndex === 2 ? 0 : selectedItemIndex + 1);
    }, 5000);
    return () => clearInterval(interval);
  }, [selectedItemIndex]);

  useEffect(() => {
    const timeRef = setInterval(() => {
      loadGameScreenFn();
    }, 15000);
    return () => clearInterval(timeRef);
  }, []);

  return <Fragment>{introAPiContent}</Fragment>;
}
