import { Box, Button, Dialog, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useCheckUserFreeSubscriptionChallengeEligibilityQuery } from "../../services/user";
import FeedbackDialog from "../../utils/FeedbackDialog";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementWidth } from "../../utils/ScreenManager";

const GameOverChallengeDialog = ({ closeFn, startDuelFn }) => {
  //#region COMMON HOOKS
  const token = useSelector((state) => state.home.userToken);
  const uuid = useSelector((state) => state.home.activeGameUUID);
  //#endregion

  //#region STATE MANAGE
  const [stateIndex, setStateIndex] = useState(1);
  //#endregion

  const {
    isLoading: isLoadingCheckUserFreeSubscriptionChallengeEligibilityAPI,
    isSuccess: isSuccessCheckUserFreeSubscriptionChallengeEligibilityAPI,
    data: checkUserFreeSubscriptionChallengeEligibilityAPIData,
    error: checkUserFreeSubscriptionChallengeEligibilityAPIError,
  } = useCheckUserFreeSubscriptionChallengeEligibilityQuery({
    userToken: token,
    gameUUID: uuid,
  });

  let stateContent;

  if (stateIndex === 1) {
    if (isLoadingCheckUserFreeSubscriptionChallengeEligibilityAPI) {
      stateContent = <LoadingDialog />;
    } else if (checkUserFreeSubscriptionChallengeEligibilityAPIError) {
      stateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            closeFn(false);
          }}
          data={{
            title: "Oops!",
            msg: checkUserFreeSubscriptionChallengeEligibilityAPIError.data?.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    } else if (isSuccessCheckUserFreeSubscriptionChallengeEligibilityAPI) {
      if (checkUserFreeSubscriptionChallengeEligibilityAPIData) {
        setStateIndex(2);
      } else {
        setTimeout(() => {
          closeFn(false);
        }, 100);
      }
    }
  } else if (stateIndex === 2) {
    stateContent = (
      <Dialog
        //fullScreen
        fullWidth={true}
        open={true}
        PaperProps={{
          style: {
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            backgroundColor: "#FFFFFF",
            boxShadow: " 0px -5px 25px 7px rgba(0, 0, 0, 0.13)",
            height: "63vh",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ height: "9vh" }}>
            <Typography
              sx={{
                fontSize: getElementWidth(36),
                fontWeight: "700",
                fontFamily: "Inter",
                color: "rgba(0, 0, 0, 1)",
                fontStyle: "normal",
              }}
            >
              Free access to
            </Typography>
            <Typography
              sx={{
                fontSize: getElementWidth(36),
                fontWeight: "700",
                fontFamily: "Inter",
                color: "rgba(0, 0, 0, 1)",
                fontStyle: "normal",
              }}
            >
              the next challenge?
            </Typography>
          </Box>
          <Box sx={{ height: "2vh" }} />
          <Box sx={{ height: "20vh" }}>
            <img height="100%" src="/images/cheer.png" alt="cheer" />
          </Box>
          <Box sx={{ height: "2vh" }} />
          <Box sx={{ paddingLeft: getElementWidth(5), paddingRight: getElementWidth(5), height: "11vh" }}>
            <Typography
              sx={{
                fontSize: getElementWidth(27),
                fontWeight: "400",
                fontFamily: "Inter",
                color: "rgba(0, 0, 0, 1)",
                fontStyle: "normal",
              }}
            >
              Challenge your friends for a duel, now!
            </Typography>
            <Typography
              sx={{
                fontSize: getElementWidth(27),
                fontWeight: "400",
                fontFamily: "Inter",
                color: "rgba(0, 0, 0, 1)",
                fontStyle: "normal",
              }}
            >
              Get into duels with 5 friends, and enter
            </Typography>
            <Typography
              sx={{
                fontSize: getElementWidth(27),
                fontWeight: "400",
                fontFamily: "Inter",
                color: "rgba(0, 0, 0, 1)",
                fontStyle: "normal",
              }}
            >
              the next challenge for free!
            </Typography>
          </Box>
          <Box sx={{ height: "2vh" }} />
          <Box sx={{ width: "100%", height: "6vh" }}>
            <Button
              style={{ backgroundColor: "rgb(210,25,25)" }}
              sx={{
                fontSize: getElementWidth(25),
                fontFamily: "Roboto",
                fontWeight: "500",
                paddingRight: getElementWidth(5),
                paddingLeft: getElementWidth(5),
                paddingTop: getElementWidth(2),
                paddingBottom: getElementWidth(2),
                color: "rgba(255, 255, 255, 1)",
                borderRadius: getElementWidth(5),
                boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => closeFn(true)}
            >
              Start duelling now
            </Button>
          </Box>
          <Box sx={{ height: "2vh" }} />
          <Box sx={{ width: "100%", height: "6vh" }}>
            <Button
              style={{ backgroundColor: "rgb(47,47,47)" }}
              sx={{
                fontSize: getElementWidth(25),
                fontFamily: "Roboto",
                fontWeight: "500",
                paddingRight: getElementWidth(5),
                paddingLeft: getElementWidth(5),
                paddingTop: getElementWidth(2),
                paddingBottom: getElementWidth(2),
                color: "rgba(255, 255, 255, 1)",
                borderRadius: getElementWidth(5),
                boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
              }}
              onClick={() => closeFn(false)}
            >
              Decline the offer
            </Button>
          </Box>
        </Box>
      </Dialog>
    );
  } else {
    stateContent = null;
  }

  return <>{stateContent}</>;
};

export default GameOverChallengeDialog;
