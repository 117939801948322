import React, { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import FeedbackDialog from "../../utils/FeedbackDialog";
import DuelListItem from "./DuelListItem";
import { useGetAllExpiredChallengesQuery } from "../../services/challengesAPI";
import { getElementWidth } from "../../utils/ScreenManager";

const ExpiredDuelsList = ({ token, commonFnObj }) => {
  //#region APIS && HOOKS
  const nextPageRef = useRef(0);
  const [page, setPage] = useState(nextPageRef.current);
  const pageCountRef = useRef(0);
  const challengeListRef = useRef([]);
  const { data, error, isLoading, refetch } = useGetAllExpiredChallengesQuery({
    userToken: token,
    page: page,
    size: 10,
  });
  //#endregion

  //#region OBSERVER FN
  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        //console.log(nextPageRef.current + " " + pageCountRef.current);
        if (nextPageRef.current < pageCountRef.current) {
          setPage(nextPageRef.current);
        }
      }
    });
    if (node) observer.current.observe(node);
  }, []);
  //#endregion

  //#region ACTIVE CHALLENGES LIST API CONTENT
  let expiredChallengeListContent = null;

  if (isLoading) {
    expiredChallengeListContent = (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (error) {
    expiredChallengeListContent = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          minHeight: `calc(100vh - ${getElementWidth(450) + "px"})`,
        }}
      >
        <Typography
          sx={{
            fontSize: getElementWidth(30),
            fontWeight: "700",
            fontFamily: "Inter",
            whiteSpace: "pre-line",
          }}
        >
          {error.errorMessage ?? "Something Went Wrong"}
        </Typography>
      </Box>
    );
  } else {
    if (data) {
      try {
        if (data.dataRows.length > 0) {
          pageCountRef.current = data.totalPages;

          if (nextPageRef.current === data.currentPage) {
            nextPageRef.current = data.currentPage + 1;
            challengeListRef.current = [
              ...challengeListRef.current,
              ...data.dataRows,
            ];
          }
          expiredChallengeListContent = challengeListRef.current.map(
            (item, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  key={index}
                  ref={
                    pageCountRef.current > 1 &&
                    challengeListRef.current.length === index + 1
                      ? lastElementRef
                      : null
                  }
                >
                  <DuelListItem
                    item={item}
                    commonFnObj={commonFnObj}
                    active={false}
                  />
                </Grid>
              );
            }
          );
        } else {
          expiredChallengeListContent = (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                width: "100%",
                minHeight: `calc(100vh - ${getElementWidth(450) + "px"})`,
              }}
            >
              <Typography
                sx={{
                  fontSize: getElementWidth(30),
                  fontWeight: "700",
                  fontFamily: "Inter",
                  whiteSpace: "pre-line",
                }}
              >
                No Past duels.
              </Typography>
            </Box>
          );
        }
      } catch (error) {
        expiredChallengeListContent = (
          <FeedbackDialog
            open={true}
            onClose={() => {
              window.location.reload();
            }}
            data={{
              title: "Oops!",
              msg: "Something Went Wrong",
            }}
          />
        );
      }
    } else {
      expiredChallengeListContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            window.location.reload();
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  return <>{expiredChallengeListContent}</>;
};

export default ExpiredDuelsList;
