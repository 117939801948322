import { configureStore, createSlice } from "@reduxjs/toolkit";
import catalogApi from "../services/catalog";
import challengesApi from "../services/challengesAPI";
import gameApi from "../services/game";
import paymentApi from "../services/paymentAPI";
import userApi from "../services/user";
import { GameModes, IMIThemeMode, PromoGameLeaderBoardStatus } from "../utils/ImiUtilData";

const homeSlice = createSlice({
  name: "home",
  initialState: {
    canGoBack: false,
    userToken: null,
    userData: null,
    themeMode: IMIThemeMode.DARK,
    //IF ACTIVE GAME MODE IS PRACTICE THEN IT IS BIND WITH A PROMO GAME
    activeGameMode: {
      type: GameModes.NORMAL,
      associatedPromoGameID: null, //THIS PROPERTY EXIST ONLY FOR PRACTICE GAMES
      showPromoIntroScreen: false,
      promoGameLeaderBoardStatus: PromoGameLeaderBoardStatus.notAvailable,
      friendChallengeCode: null,
    },
    activeUrlData: null,
    activeGameUUID: null,
    activeSubScreen: null,
    favGameList: [],
    isIOSDevice: false,
    isInFirefox: false,
    socketData: null,
    isOTPLoginDialogActive: {
      state: false,
      callbackFn: null,
    },
  },
  reducers: {
    setUserToken: (state, action) => {
      state.userToken = action.payload;
    },
    setUserData: (state, action) => {
      if (action.payload) {
        state.userData = {
          name: action.payload.name,
          image: action.payload.image,
          id: action.payload.id,
        };
      } else {
        state.userData = null;
      }
    },
    activateBack: (state, action) => {
      state.canGoBack = action.payload;
    },
    changeTheme: (state, action) => {
      state.themeMode = action.payload;
    },
    setActiveGameMode: (state, action) => {
      state.activeGameMode = {
        type: action.payload.type,
        associatedPromoGameID: action.payload.associatedPromoGameID,
        showPromoIntroScreen: action.payload.showPromoIntroScreen,
        promoGameLeaderBoardStatus: action.payload.promoGameLeaderBoardStatus,
        friendChallengeCode: action.payload.friendChallengeCode,
      };
    },
    setActiveUrlData: (state, action) => {
      state.activeUrlData = {
        isGame: action.payload.isGame,
        url: action.payload.url,
        justSubscribedBool: action.payload.justSubscribedBool,
      };
    },
    setActiveGameUUID: (state, action) => {
      state.activeGameUUID = action.payload;
    },
    //NOT SUBSCRIPTION SCREEN
    setActiveSubScreen: (state, action) => {
      state.activeSubScreen = action.payload;
    },
    setFavGameList: (state, action) => {
      state.favGameList = action.payload;
    },
    setIsIOSDevice: (state, action) => {
      state.isIOSDevice = action.payload;
    },
    setIsInFirefox: (state, action) => {
      state.isInFirefox = action.payload;
    },
    setSocketData: (state, action) => {
      state.socketData = {
        type: action.payload.type,
        location: action.payload.location,
        data: action.payload.data,
      };
    },
    setIsOTPLoginDialogActive: (state, action) => {
      state.isOTPLoginDialogActive = {
        state: action.payload.state,
        callbackFn: action.payload.callbackFn,
      };
    },
  },
});

const store = configureStore({
  reducer: {
    home: homeSlice.reducer,
    [catalogApi.reducerPath]: catalogApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [gameApi.reducerPath]: gameApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [challengesApi.reducerPath]: challengesApi.reducer,
  },
  //CACHING WILL BE HANDLED BY THE MIDDLEWARE
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(catalogApi.middleware).concat(userApi.middleware).concat(gameApi.middleware).concat(paymentApi.middleware).concat(challengesApi.middleware),
});

export const homeActions = homeSlice.actions;

export default store;
