import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { GameModes, PromoGameLeaderBoardStatus } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";

const ImiSearchResult = ({ item, openGameFn }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));
  const gameType = item.additional_info.fiji_game_type ?? GameModes.NORMAL;

  const classes = useStyles();
  //#endregion

  //#region HOOKS & APIS
  //#endregion

  return (
    <Card
      elevation={0}
      onClick={() => {
        if (gameType === GameModes.NORMAL || gameType === GameModes.PRACTICE) {
          openGameFn(item.item_url, item.additional_info.uuid, true);
        } else if (gameType === GameModes.COMPETITION || gameType === GameModes.SPOT) {
          if (item.item_url) {
            let isPromoGameLeaderBoardActive = item.additional_info.active_leaderboard;
            let gameUrl = isPromoGameLeaderBoardActive ? item.item_url : item.additional_info.teaser_game_url;
            openGameFn(gameUrl, item.additional_info.uuid, true, {
              type: gameType,
              associatedPromoGameID: null,
              showPromoIntroScreen: true,
              promoGameLeaderBoardStatus: isPromoGameLeaderBoardActive ? PromoGameLeaderBoardStatus.active : PromoGameLeaderBoardStatus.notActive,
            });
          }
        }
      }}
      style={{
        display: "flex",
        width: "100%",
        height: getElementWidth(220),
        marginTop: getElementWidth(20),
        paddingLeft: getElementWidth(15),
        paddingRight: getElementWidth(15),
      }}
    >
      <CardMedia
        image={item.item_cover_image}
        title={item.item_name}
        style={{
          width: getElementWidth(170),
          height: "100%",
          borderRadius: 0,
        }}
      />
      <CardContent
        style={{
          padding: 0,
          paddingLeft: getElementWidth(15),
          width: getElementWidth(350),
        }}
      >
        <Typography color="text.primary" fontWeight="bold" fontSize={getElementWidth(26)}>
          {" "}
          {item.item_name}
        </Typography>
        <Typography color="text.secondary" fontSize={getElementWidth(22)}>
          {" "}
          {item.item_description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ImiSearchResult;
