import { Backdrop, CircularProgress } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useLayoutEffect } from "react";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useGetCatalogListQuery } from "../../services/catalog";
import { useGetGameDetailsMutation, useGetPracticeGameDetailsMutation } from "../../services/game";
import { homeActions } from "../../store";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { GameModes, PromoGameLeaderBoardStatus } from "../../utils/ImiUtilData";
import ImiImageList from "./ImiImageList";

const ImiHome = ({ openGameFn }) => {
  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);
  const favGameList = useSelector((state) => state.home.favGameList);
  const locationData = useLocation().state ?? null;
  const dispatch = useDispatch();
  const socketData = useSelector((state) => state.home.socketData);
  const additionalUrlParams = useRef(null);

  //CATALOG API

  const { data: catalogAPIResponse, error: catalogAPIError, isLoading: isLoadingCatalogAPI, refetch } = useGetCatalogListQuery();

  //GET GAME DETAILS API
  const [getGameUrl, { data: getGameApiResponse, isLoading: isLoadingGetGameApi, error: getGameApiError }] = useGetGameDetailsMutation();

  //GET PRACTICE GAME DETAILS API
  const [getPracticeGameDataAPI, { isLoading: isLoadingGetPracticeGameDetailsAPI, isSuccess: isSuccessGetPracticeGameDetailsAPI, data: getPracticeGameDetailsAPIData, error: getPracticeGameDetailsAPIError }] = useGetPracticeGameDetailsMutation();

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  useEffect(() => {
    //CHECK FOR GAME ID IN URL
    const url = new URL(window.location.href);

    const gameId = url.searchParams.get("gameId") ?? locationData?.gameId;

    const _justSubscribed = url.searchParams.get("firsttimesub");
    if (_justSubscribed) {
      additionalUrlParams.current = {
        justSubscribedBool: true,
      };
    }

    //FOR A DIRECT ULR GAME DON'T USE A PRACTICE GAME ID
    if (gameId) {
      const _friendChallengeCode = url.searchParams.get("friendChallengeCode");
      if (_friendChallengeCode) {
        console.log("here");
        additionalUrlParams.current = {
          friendChallengeCode: _friendChallengeCode,
        };
      }

      getGameUrl({ uuid: gameId });
    } else if (locationData?.openPracticeGame) {
      getPracticeGameDataAPI({ gameUUID: locationData.associatedPromoGameID });
    }

    //ONE SIGNAL
    window.OneSignal = window.OneSignal || [];
    const OneSignal = window.OneSignal;

    OneSignal.push(() => {
      OneSignal.init({
        appId: "54c9cc12-1675-4b73-a6fa-965db15248f7",
      });
    });
    OneSignal.push([
      "addListenerForNotificationOpened",
      function (data) {
        //console.log("Received NotificationOpened:");
        //console.log(data);
        if (data) {
          let newUrl = new URL(data.data.url);
          let linkUrl = newUrl.searchParams.get("url");
          let gameUUID = newUrl.searchParams.get("uuid");
          openGameFn(linkUrl, gameUUID, true);
        }
      },
    ]);
  }, []);

  useEffect(() => {
    let _gameUrl = null;
    let _gameID = null;
    let _gameType = null;
    let _associatedPromoGameID = null;
    let _showPromoIntroScreen = false;
    let _promoGameLeaderBoardStatus = PromoGameLeaderBoardStatus.notAvailable;
    let _justSubscribedBool = false; //CAME HERE AFTER SUBSCRIBING
    let _friendChallengeCode = null;

    if (getGameApiResponse) {
      _gameID = getGameApiResponse.uuid;

      //CHECK FOR FRIEND CHALLENGE CODE
      if (additionalUrlParams.current?.friendChallengeCode) {
        _friendChallengeCode = additionalUrlParams.current?.friendChallengeCode;
        additionalUrlParams.current = null;
      }

      _gameType = getGameApiResponse.game_type;

      //SPOT & COMPETITION
      if (_gameType === GameModes.SPOT || _gameType === GameModes.COMPETITION) {
        _gameUrl = getGameApiResponse.active_leaderboard ? getGameApiResponse.url : getGameApiResponse.teaser_game_url;

        if (additionalUrlParams.current?.justSubscribedBool) {
          _justSubscribedBool = true;
          additionalUrlParams.current = null;
        }

        _showPromoIntroScreen = !_justSubscribedBool;
        _promoGameLeaderBoardStatus = getGameApiResponse.active_leaderboard ? PromoGameLeaderBoardStatus.active : PromoGameLeaderBoardStatus.notActive;
      } else if (_gameType === GameModes.NORMAL || _gameType === GameModes.PRACTICE) {
        _gameUrl = getGameApiResponse.url;
      } else {
        console.log("GAME TYPE ERROR");
      }
    } else if (getPracticeGameDetailsAPIData) {
      _gameUrl = getPracticeGameDetailsAPIData.url;
      _gameID = getPracticeGameDetailsAPIData.uuid;

      _gameType = GameModes.PRACTICE;
      _associatedPromoGameID = locationData.associatedPromoGameID;
      _promoGameLeaderBoardStatus = locationData.promoGameLeaderBoardStatus;
    }

    let gameMode = {
      type: _gameType,
      associatedPromoGameID: _associatedPromoGameID,
      showPromoIntroScreen: _showPromoIntroScreen,
      promoGameLeaderBoardStatus: _promoGameLeaderBoardStatus,
      friendChallengeCode: _friendChallengeCode,
    };

    if (_gameUrl) {
      openGameFn(_gameUrl, _gameID, true, gameMode, _justSubscribedBool);
    }
  }, [getGameApiResponse, getPracticeGameDetailsAPIData]);

  //#endregion

  //#region CATALOG LIST API STATE
  let catalogListAPIContent;

  if (isLoadingCatalogAPI) {
    catalogListAPIContent = (
      <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
    );
  } else if (catalogAPIError) {
    catalogListAPIContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          window.location.reload();
        }}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong",
        }}
      />
    );
  } else {
    if (catalogAPIResponse) {
      try {
        catalogListAPIContent = catalogAPIResponse.map((item) => {
          //console.log(item);
          return <ImiImageList token={userToken} title={item.catalog_name} type={item.catalog_type} key={item.catalog_id} id={item.catalog_id} openGameFn={openGameFn} favGameList={favGameList} socketData={socketData} />;
        });
      } catch (error) {
        catalogListAPIContent = (
          <FeedbackDialog
            open={true}
            onClose={() => {
              window.location.reload();
            }}
            data={{
              title: "Oops!",
              msg: "Something Went Wrong",
            }}
          />
        );
      }
    } else {
      catalogListAPIContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            window.location.reload();
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  //#region GET GAME DATA API STATES
  let getGameApiContent;
  if (isLoadingGetGameApi) {
    getGameApiContent = (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  } else if (getGameApiError) {
    console.log(getGameApiError);
  }
  //#endregion

  //#region GET PRACTICE GAME DATA API STATES
  let getPracticeGameAPIContent;
  if (isLoadingGetPracticeGameDetailsAPI) {
    getGameApiContent = (
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={true}>
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
  //#endregion

  //#endregion

  return (
    <Fragment>
      {catalogListAPIContent}
      {getGameApiContent}
      {getPracticeGameAPIContent}
      <DummyBottomSpace />
    </Fragment>
  );
};

export default ImiHome;
