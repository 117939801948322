import { Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { homeActions } from "../../store";
import { DATA_KEY, ImiPathsUrls } from "../../utils/ImiUtilData";
import LoginDialog from "../../utils/LogIn/LoginDialog";
import ImiSearchPopular from "../Search/ImiSearchPopular";
import MyDuelsList from "./MyDuelsList";

const ImiDuels = ({ openGameFn }) => {
  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    if (userToken === null) {
      let _userData = localStorage.getItem(DATA_KEY);

      if (_userData) {
        //dispatch(homeActions.setUserToken(_userData));
        _userData = JSON.parse(_userData);

        dispatch(homeActions.setUserToken(_userData.token));
        dispatch(
          homeActions.setUserData({
            name: _userData.name,
            image: _userData.image,
          })
        );
      }
    }
  }, []);
  //#endregion

  return (
    <>
      {userToken ? (
        <MyDuelsList token={userToken} />
      ) : (
        <LoginDialog
          open={true}
          onClose={(state) => {
            //LOGIN FAILED
            if (!state) {
            }

            navigate(ImiPathsUrls.profile, {
              replace: true,
            });
          }}
        />
      )}
    </>
  );
};

export default ImiDuels;
