import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const paymentApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    makePayment: builder.query({
      query: ({ userToken, gameUUID, destUrl }) => ({
        url: `/lg/v1/one-time-payment`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          type: "VODAFONE-FIJI",
          game_uuid: gameUUID,
          additional_info: destUrl,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    checkForFreeSubscription: builder.query({
      query: ({ userToken, gameUUID }) => ({
        url: `/lg/v1/one-time-payment/check-free-subscription?game_uuid=${gameUUID}`,
        method: "GET",
        headers: {
          "x-platform": "vodafone-fiji",
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    activateFreeSubscription: builder.mutation({
      query: ({ userToken, gameUUID }) => ({
        url: `/lg/v1/one-time-payment/free-subscription/activate?game_uuid=${gameUUID}`,
        method: "POST",
        headers: {
          "x-platform": "vodafone-fiji",
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const { useMakePaymentQuery, useCheckForFreeSubscriptionQuery, useActivateFreeSubscriptionMutation } = paymentApi;
export default paymentApi;
