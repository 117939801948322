import { Facebook, StayCurrentPortrait } from "@mui/icons-material";
import { Button, Typography } from "@mui/material";
import { GoogleLogin } from "@react-oauth/google";
import React, { Fragment } from "react";
import { fbAppID, ImiPathsUrls, legalContracts } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import CommonItemScript from "./CommonItemScript";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { homeActions } from "../../store";

const UserLoggedOutContent = ({ responseFacebook, responseGoogle, responseGoogleError, otpBtnClick, onAppearanceBtnClick }) => {
  //#region API && HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  //#region TERMS &* PRIVACY POLICY FN
  const termsCond_BtnClick = () => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: false,
        url: legalContracts.termsCondUrl,
      })
    );

    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };

  const privacyPolicy_BtnClick = () => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: false,
        url: legalContracts.privacyPolicyUrl,
      })
    );

    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };
  //#endregion

  return (
    <Fragment>
      <div style={{ height: getElementWidth(20) }}></div>

      <FacebookLogin
        appId={fbAppID}
        autoLoad={false}
        callback={responseFacebook}
        isMobile={true}
        disableMobileRedirect={true}
        render={(renderProps) => (
          <Button
            onClick={renderProps.onClick}
            style={{
              backgroundColor: "rgba(75, 111, 167,255)",
              display: "flex",
              flexDirection: "row",
              width: getElementWidth(480),
              height: getElementWidth(80),
              //display : 'flex',
              marginTop: getElementWidth(20),
              //marginBottom : getElementWidth(20),
              marginLeft: "auto",
              marginRight: "auto",
              justifyContent: "center",
            }}
            startIcon={<Facebook sx={{ color: "white" }} />}
          >
            <Typography color="white" fontSize={getElementWidth(28)} fontWeight="bold" textTransform="none">{`Facebook`}</Typography>
          </Button>
        )}
      />

      <div
        style={{
          borderRadius: "4px",
          backgroundColor: "#1a73e8",
          display: "flex",
          flexDirection: "row",
          width: getElementWidth(480),
          height: getElementWidth(80),
          marginTop: getElementWidth(20),
          //marginBottom : getElementWidth(20),
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <GoogleLogin logo_alignment="center" type="standard" size="medium" shape="rectangular" theme="filled_blue" onSuccess={responseGoogle} onError={responseGoogleError} />
      </div>

      <Button
        onClick={otpBtnClick}
        style={{
          display: "flex",
          flexDirection: "row",
          backgroundColor: "rgba(206, 60, 54,255)",
          width: getElementWidth(480),
          height: getElementWidth(80),
          //display : 'flex',
          marginTop: getElementWidth(20),
          //marginBottom : getElementWidth(20),
          marginLeft: "auto",
          marginRight: "auto",
          justifyContent: "center",
        }}
        startIcon={<StayCurrentPortrait sx={{ color: "white" }} />}
      >
        <Typography color="white" fontSize={getElementWidth(28)} fontWeight="bold" textTransform="none">{`OTP`}</Typography>
      </Button>

      <div style={{ height: getElementWidth(40) }}></div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          display="inline"
          style={{
            textTransform: "none",
            fontSize: getElementWidth(20),
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          By Logging in, you agree to our&nbsp;
        </Typography>
        <Typography
          style={{
            color: "rgba(206, 60, 54,255)",
            textTransform: "none",
            fontSize: getElementWidth(20),
            textAlign: "center",
            fontWeight: "500",
          }}
          display="inline"
          onClick={termsCond_BtnClick}
        >
          {" "}
          Terms and Conditions
        </Typography>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Typography
          display="inline"
          style={{
            textTransform: "none",
            fontSize: getElementWidth(20),
            textAlign: "center",
            fontWeight: "500",
          }}
        >
          and acknowledge that you have read the&nbsp;
        </Typography>
        <Typography
          style={{
            color: "rgba(206, 60, 54,255)",
            textTransform: "none",
            fontSize: getElementWidth(20),
            textAlign: "center",
            fontWeight: "500",
          }}
          display="inline"
          onClick={privacyPolicy_BtnClick}
        >
          {"Privacy Policy."}
        </Typography>
      </div>
      <CommonItemScript onAppearanceBtnClick={onAppearanceBtnClick} />
    </Fragment>
  );
};

export default UserLoggedOutContent;
