import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useMakePaymentQuery } from "../../../services/paymentAPI";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import { GameModes } from "../../../utils/ImiUtilData";
import LoadingDialog from "../../../utils/LoadingDialog";

const PaymentDialog = ({ activeGameModeData, onClose }) => {
  //#region COMMON HOOKS
  const token = useSelector((state) => state.home.userToken);
  const uuid = useSelector((state) => state.home.activeGameUUID);
  //#endregion

  //#region PAYMENT API

  const destUrlOrigin = `${window.location.origin}/home`;
  const destUrlParams = activeGameModeData.type === GameModes.PRACTICE ? `?gameId=${activeGameModeData.associatedPromoGameID}&practiceGameId=${uuid}` : `?gameId=${uuid}`;
  const destinationUrl = destUrlOrigin + destUrlParams;

  console.log(destinationUrl);

  const {
    isLoading: isLoadingPaymentAPI,
    isSuccess: isSuccessPaymentAPI,
    data: paymentAPIData,
    error: paymentAPIError,
  } = useMakePaymentQuery({
    userToken: token,
    gameUUID: activeGameModeData.type === GameModes.PRACTICE ? activeGameModeData.associatedPromoGameID : uuid,
    destUrl: destinationUrl,
  });

  useEffect(() => {
    if (paymentAPIData && !paymentAPIData.subscription_success) {
      if (paymentAPIData.additionalInfo?.length > 0) {
        onClose(paymentAPIData.additionalInfo[0]?.value ?? "");
      } else {
        onClose();
      }
    }
  }, [paymentAPIData]);

  let paymentAPIStateContent = null;

  if (isLoadingPaymentAPI) {
    paymentAPIStateContent = <LoadingDialog />;
  } else if (paymentAPIError) {
    console.log(paymentAPIError);

    paymentAPIStateContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          onClose();
        }}
        data={{
          title: "Oops!",
          msg: paymentAPIError.data?.errorMessage ?? "Something Went Wrong",
        }}
      />
    );
  } else if (isSuccessPaymentAPI) {
    console.log(paymentAPIData);

    if (!paymentAPIData || paymentAPIData.subscription_success) {
      paymentAPIStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            onClose();
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  return <>{paymentAPIStateContent}</>;
};

export default PaymentDialog;
