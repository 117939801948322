import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import React from "react";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";

const PromoGameListConsentDialog = ({ onCloseFn }) => {
  return (
    <Dialog fullWidth={true} open={true}>
      <DialogTitle
        sx={{
          height: getElementHeight(50),
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        Well done
      </DialogTitle>
      <DialogContent dividers>
        <DialogContentText
          sx={{
            height: getElementHeight(80),
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
          }}
        >
          Feeling up to the challenge ?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onCloseFn(false)}>
          <Typography
            color="text.primary"
            style={{
              textTransform: "none",
              fontSize: getElementWidth(25),
            }}
            noWrap={true}
          >
            NOT UPTO IT
          </Typography>
        </Button>
        <Button onClick={() => onCloseFn(true)}>
          <Typography
            color="text.neutral"
            noWrap={true}
            style={{
              textTransform: "none",
              fontSize: getElementWidth(25),
            }}
          >
            I'M READY
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PromoGameListConsentDialog;
