import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import React from "react";

const FeedbackDialog = ({ onClose, open, data }) => {
  const onActionBtnClick = () => {
    onClose();
  };

  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle>{data.title}</DialogTitle>
      <DialogContent dividers>
        <DialogContentText>{data.msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onActionBtnClick} color="primary" autoFocus>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FeedbackDialog;
