import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { commonObjContext } from "../../App";
import { GameModes, ImiPathsUrls, PromoGameLeaderBoardStatus } from "../../utils/ImiUtilData";

const ImiGameFrame = () => {
  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const urlData = useSelector((state) => state.home.activeUrlData);
  const gameUUID = useSelector((state) => state.home.activeGameUUID);
  const activeGameMode = useSelector((state) => state.home.activeGameMode);
  const navigate = useNavigate();

  const iframeRef = useContext(commonObjContext).iframeRef;

  useEffect(() => {
    if (urlData === null) {
      navigate(ImiPathsUrls.home, {
        replace: true,
      });
    }
  }, []);

  //#endregion

  let url = "";

  if (urlData) {
    url = `${urlData.url}?platform=pwa&version=200`;

    if (urlData.isGame) {
      if (userToken) {
        url += `&user=${userToken}`;
      }

      if (urlData.justSubscribedBool) {
        url += "&firsttimesub=true";
      }

      //PRACTICE GAMES CAN BE OPENED DIRECLY FROM HOME SCREEN (associatedPromoGameID WILL BE NULL)
      //OPEN A PRACTICE GAME ASSOCIATED WITH A PROMO GAME -> ACTIVE OR DEACTIVE LEADERBOARD
      if (activeGameMode.type === GameModes.PRACTICE && activeGameMode.associatedPromoGameID) {
        url += `&promoGameID=${activeGameMode.associatedPromoGameID}`;
      }

      //SINCE THE LEADERBOARD IS NOT ACTIVE TEASER GAME WILL BE OPENED
      if ((activeGameMode.type === GameModes.SPOT || activeGameMode.type === GameModes.COMPETITION) && activeGameMode.promoGameLeaderBoardStatus === PromoGameLeaderBoardStatus.notActive) {
        url += `&promoGameID=${gameUUID}`;
      }

      if (activeGameMode.friendChallengeCode) {
        url += `&friendChallengeCode=${activeGameMode.friendChallengeCode}`;
      }
      if (activeGameMode.type) {
        url += `&gameType=${activeGameMode.type}`;
      }
    }
  }

  console.log(url);

  return (
    <iframe
      ref={iframeRef}
      title="gameFrame"
      src={url}
      allow="microphone; accelerometer; encrypted-media"
      style={{
        width: "100%",
        height: "100%",
        border: "0",
        position: "fixed",
        top: "0",
        left: "0",
        overflow: "hidden",
      }}
    ></iframe>
  );
};

export default ImiGameFrame;
