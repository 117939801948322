import { AppBar, Button, Dialog, IconButton, Tab, Tabs, Toolbar, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useState } from "react";
import CreateNewP2PDuel from "./CreateNewP2PDuel";
import PropTypes from "prop-types";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import { ArrowBackIos } from "@mui/icons-material";
import { useSelector } from "react-redux";
import GameWiseExpiredP2PDuelsList from "./GameWiseExpiredP2PDuelsList";
import GameWiseActiveP2PDuelsList from "./GameWiseActiveP2PDuelsList";

//#region TAB PANEL
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component={"div"} variant={"body1"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
//#endregion

const P2PDuelDialog = ({ onCloseFn }) => {
  //#region API && HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  const gameUUID = useSelector((state) => state.home.activeGameUUID);

  const [value, setValue] = useState(0);
  const [openDuel, setOpenDuel] = useState(false);
  //#endregion

  //#region UTIL FNS
  const openNewChallengeDialogFn = () => {
    setOpenDuel(true);
  };

  const closeNewChallengeDialogFn = () => {
    setOpenDuel(false);
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  //#endregion

  return (
    <Dialog
      fullScreen
      open={true}
      fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: "#FFFFFF",
        },
      }}
    >
      <AppBar elevation={0} position="sticky" sx={{ backgroundColor: "white" }}>
        <Box sx={{ width: "100vw", height: getElementHeight(180), display: "flex", justifyContent: "space-around" }}>
          <Toolbar>
            <Box sx={{ width: "70vw", display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
              <IconButton
                edge="start"
                sx={{
                  color: "rgba(17, 17, 17, 1)",
                }}
                onClick={() => {
                  onCloseFn();
                }}
              >
                <ArrowBackIos />
              </IconButton>
              <Typography
                component="div"
                sx={{
                  color: "rgba(0, 0, 0, 0.87)",
                  fontSize: getElementWidth(40),
                  fontWeight: "400",
                  fontFamily: "Roboto",
                }}
              >
                Duels
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={openNewChallengeDialogFn}
                style={{
                  background: `url("/images/CreateChallengeBtn.png") no-repeat center/100%`,
                  whiteSpace: "nowrap",
                  backgroundSize: "contain",
                  backgroundPosition: "center center",
                  height: getElementHeight(120),
                  width: getElementHeight(120),
                  borderRadius: "50%",
                }}
              ></Button>
            </Box>
          </Toolbar>
        </Box>
      </AppBar>
      <Box sx={{ width: "100%" }}>
        <Tabs variant="fullWidth" centered textColor="primary" indicatorColor="primary" value={value} onChange={handleTabChange}>
          <Tab label="Ongoing" {...a11yProps(0)} />
          <Tab label="Expired" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <GameWiseActiveP2PDuelsList token={userToken} gameUUID={gameUUID} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <GameWiseExpiredP2PDuelsList token={userToken} gameUUID={gameUUID} />
        </TabPanel>
      </Box>
      {openDuel ? <CreateNewP2PDuel token={userToken} gameUUID={gameUUID} onCloseFn={closeNewChallengeDialogFn} /> : null}
    </Dialog>
  );
};

export default P2PDuelDialog;
