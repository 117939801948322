import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const challengesApi = createApi({
  reducerPath: "challengesApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    createNewChallenge: builder.mutation({
      query: ({ uuid, userToken, endDate }) => ({
        url: `/lg/p2pchallenge?game_uuid=${uuid}&end_date=${endDate}&country_id=2`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getAllActiveChallenges: builder.query({
      query: ({ userToken, page, size }) => ({
        url: `/lg/p2pchallenge/challenges/by-type?size=${size}&page=${page}&country_id=2`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
    }),
    getAllExpiredChallenges: builder.query({
      query: ({ userToken, page, size }) => ({
        url: `/lg/p2pchallenge/challenges/by-type?size=${size}&page=${page}&type=1&country_id=2`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
    }),
    getGameWiseAllActiveChallenges: builder.query({
      query: ({ uuid, userToken, page, size }) => ({
        url: `/lg/p2pchallenge/leaderboard-details/${uuid}?size=${size}&page=${page}&country_id=2`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
    }),
    getGameWiseAllExpiredChallenges: builder.query({
      query: ({ uuid, userToken, page, size }) => ({
        url: `/lg/p2pchallenge/leaderboard-details/${uuid}?size=${size}&page=${page}&type=1&country_id=2`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
      keepUnusedDataFor: 0,
    }),
    joinChallenge: builder.query({
      query: ({ userToken, code }) => ({
        url: `/lg/p2pchallenge/user?token=${code}`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useCreateNewChallengeMutation,
  useGetAllActiveChallengesQuery,
  useGetAllExpiredChallengesQuery,
  useGetGameWiseAllActiveChallengesQuery,
  useGetGameWiseAllExpiredChallengesQuery,
  useJoinChallengeQuery,
} = challengesApi;
export default challengesApi;
