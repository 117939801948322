import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Radio, RadioGroup } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { homeActions } from "../../store";
import { IMIThemeMode, THEME_DATA } from "../../utils/ImiUtilData";

const AppearanceDialog = ({ open, onClose, value }) => {
  const dispatch = useDispatch();

  const handleCancel = () => {
    onClose();
  };

  const handleChange = (event) => {
    dispatch(homeActions.changeTheme(event.target.value));
    localStorage.setItem(THEME_DATA, event.target.value);
  };

  //console.log(props.value);

  return (
    <Dialog fullWidth={true} open={open}>
      <DialogTitle>Appearance</DialogTitle>
      <DialogContent dividers>
        <RadioGroup name="theme" value={value} onChange={handleChange}>
          <FormControlLabel value={IMIThemeMode.DARK} key="Dark" control={<Radio /*checked*/ />} label="Dark" />
          <FormControlLabel value={IMIThemeMode.LIGHT} key="Light" control={<Radio />} label="Light" />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppearanceDialog;
