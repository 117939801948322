import { Edit } from "@mui/icons-material";
import { Avatar, Box, Button, Container, IconButton, Typography } from "@mui/material";
import React, { Fragment, useState } from "react";
import FileResizer from "react-image-file-resizer";
import { useUpdateUserImageMutation } from "../../services/user";
import FeedbackDialog from "../../utils/FeedbackDialog";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementWidth } from "../../utils/ScreenManager";
import CommonItemScript from "./CommonItemScript";

const UserLoggedInContent = ({ userToken, userData, openEditNameDialogFn, onAppearanceBtnClick, signOut_BtnClick, refetchUserProfileAPI }) => {
  //#region APIS
  const [updateUserImageAPICall, { isLoading: isLoadingUpdateUserImageAPI, error: updateUserImageAPIError, isSuccess: isSuccessUpdateUserImageAPI }] = useUpdateUserImageMutation();
  //#endregion

  //#region HOOKS
  const [apiContentStateIndex, setAPIContentStateIndex] = useState(false);
  //#endregion

  //#region PAGE MAIN CONTENT
  const resizeFile = (file) =>
    new Promise((resolve) => {
      FileResizer.imageFileResizer(
        file,
        128,
        128,
        "png",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "file"
      );
    });

  const userImageInputOnChangeFn = async (e) => {
    var image = e.target.files[0];
    image = await resizeFile(image);

    let formData = new FormData();
    formData.append("file", image);

    setAPIContentStateIndex(1);
    updateUserImageAPICall({ userToken: userToken, image: formData });
  };

  let loggedInPageContent = (
    <Fragment>
      <Box
        sx={{
          height: getElementWidth(30),
        }}
      />
      <Container
        style={{
          display: "flex",
          height: getElementWidth(130),
          padding: "0px",
        }}
      >
        <Box
          sx={{
            width: getElementWidth(295),
          }}
        />
        <Avatar
          variant="square"
          style={{
            width: getElementWidth(130),
            height: "auto",
          }}
          src={userData.image+`?${new Date()}`}
        />
        <Box
          sx={{
            width: getElementWidth(295),
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton color="primary" component="label">
            <input hidden accept="image/*" type="file" onChange={userImageInputOnChangeFn} />
            <Edit />
          </IconButton>
        </Box>
      </Container>
      <Container
        style={{
          display: "flex",
          height: getElementWidth(80),
          padding: "0px",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: getElementWidth(270),
          }}
        />
        <Box
          sx={{
            width: getElementWidth(180),
          }}
        >
          <Typography
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: getElementWidth(10),
              marginTop: getElementWidth(10),
              fontWeight: "bold",
              fontSize: getElementWidth(25),
            }}
          >
            {userData.name.substring(0, 10)}
          </Typography>
        </Box>

        <Box
          sx={{
            width: getElementWidth(270),
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton color="primary" component="label" onClick={openEditNameDialogFn}>
            <Edit />
          </IconButton>
        </Box>
      </Container>

      <Typography
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: getElementWidth(10),
          marginTop: getElementWidth(10),
          fontWeight: "bold",
          fontSize: getElementWidth(25),
        }}
      >
        {userData.id.toString().padStart(8, "0")}
      </Typography>
      <CommonItemScript onAppearanceBtnClick={onAppearanceBtnClick} />
      <Button
        color="primary"
        onClick={signOut_BtnClick}
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: getElementWidth(40),
          marginLeft: "auto",
          marginRight: "auto",
          textTransform: "none",
          fontSize: getElementWidth(20),
        }}
      >
        Sign Out
      </Button>
    </Fragment>
  );
  //#endregion

  //#region UPDATE USERIMAGE API STATES
  let apiStateContent = null;

  if (apiContentStateIndex === 1) {
    if (isLoadingUpdateUserImageAPI) {
      apiStateContent = <LoadingDialog />;
    } else if (isSuccessUpdateUserImageAPI) {
      apiStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            refetchUserProfileAPI();
            setAPIContentStateIndex(0);
          }}
          data={{
            title: "Success",
            msg: "Successfully updated!",
          }}
        />
      );
    } else if (updateUserImageAPIError) {
      setAPIContentStateIndex(2);
    }
  } else if (apiContentStateIndex.index === 2) {
    apiStateContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {}}
        data={{
          title: "Oops!",
          msg: "Something Went Wrong...!",
        }}
      />
    );
  }

  //#endregion

  return (
    <>
      {loggedInPageContent}
      {apiStateContent}
    </>
  );
};

export default UserLoggedInContent;
