import { LockRounded } from "@mui/icons-material";
import { Backdrop, Box, CircularProgress, Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useGetSearchListQuery } from "../../services/catalog";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { getElementWidth } from "../../utils/ScreenManager";
import ImiSearchResult from "./ImiSearchResult";

const ImiSearchList = ({ search, openGameFn }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();

  const styleConfig = {
    text: {
      display: "flex",
      justifyContent: "center",
      fontSize: getElementWidth(25),
      position: "fixed",
      top: "50%",
      left: "50%",
      "-webkit-transform": "translate(-50%, -50%)",
      transform: "translate(-50%, -50%)",
    },
  };
  //#endregion

  //#region HOOKS & APIS
  const { data, error, isLoading } = useGetSearchListQuery({
    size: 10,
    search: search,
  });
  //#endregion

  //#region SEARCH LIST API CONTENT
  let searchListAPIContent = null;

  if (isLoading) {
    searchListAPIContent = (
      <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
    );
  } else if (error) {
    searchListAPIContent = (
      <Typography
        style={{
          ...styleConfig.text,
        }}
      >
        Something Went Wrong
      </Typography>
    );
  } else {
    if (data) {
      try {
        if (data.length > 0) {
          searchListAPIContent = data.map((item) => {
            return (
              <Grid item xs={12} key={item.additional_info.game_id}>
                <Box style={{ position: "relative" }}>
                  <ImiSearchResult item={item} openGameFn={openGameFn} />
                  <Box style={{ position: "absolute", top: "40%", left: "13%", transform: "translate(-40%, -13%)", msTransform: "translate(-40%, -13%)" }}>{!item.item_url && <LockRounded fontSize={"large"} color="primary.light" />}</Box>
                </Box>
              </Grid>
            );
          });
        } else {
          searchListAPIContent = (
            <Typography
              style={{
                ...styleConfig.text,
              }}
            >
              No Results
            </Typography>
          );
        }
      } catch (error) {
        searchListAPIContent = (
          <FeedbackDialog
            open={true}
            onClose={() => {
              window.location.reload();
            }}
            data={{
              title: "Oops!",
              msg: "Something Went Wrong",
            }}
          />
        );
      }
    } else {
      searchListAPIContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            window.location.reload();
          }}
          data={{
            title: "Oops!",
            msg: "Something Went Wrong",
          }}
        />
      );
    }
  }
  //#endregion

  return (
    <Grid container spacing={1}>
      {searchListAPIContent}
      <DummyBottomSpace />
    </Grid>
  );
};

export default ImiSearchList;
