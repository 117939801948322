import { Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useUpdateUserNameMutation } from "../../services/user";
import FeedbackDialog from "../../utils/FeedbackDialog";
import LoadingDialog from "../../utils/LoadingDialog";
import { getElementWidth } from "../../utils/ScreenManager";

const EditNameDialog = ({ userToken, currentName, onCloseFn, refetchUserProfileAPI }) => {
  //#region API && HOOKS
  const [userName, setUserName] = useState({
    value: currentName,
    disable: true,
  });

  const [apiStateIndex, setApiStateIndex] = useState({
    index: 0,
    msg: "",
    callBack: null,
  });

  //#endregion

  //#region ON NAME CHANGED FN
  const onNameChanged = (event) => {
    //console.log(telcoList.current[0].regex);
    let name = event.target.value;

    if (name.length === 0) {
      setUserName({
        value: name,
        disable: true,
      });
      return;
    }

    if (name.length >= 20) {
      return;
    }

    setUserName({
      value: name,
      disable: false,
    });

    //onSearch(event.target.value);
  };
  //#endregion

  //#region UPDATE NAME API
  const [updateName, { isLoading: isLoadingUpdateNameRequestApi, error: updateNameRequestAPIError, isSuccess: isSuccessUpdateNameRequestAPI }] = useUpdateUserNameMutation();
  //#endregion

  //#region UPDATE NAME BTN CLICK FN
  const updateNameBtnClickFn = () => {
    updateName({ userToken: userToken, userName: userName.value });
    setApiStateIndex({
      index: 1,
      msg: "",
      callBack: () =>
        setApiStateIndex({
          index: 0,
          msg: "",
        }),
    });
  };
  //#endregion
  //#region UPDATE USERNAME API STATES
  let updateNameContent;
  if (apiStateIndex.index === 1) {
    if (isLoadingUpdateNameRequestApi) {
      updateNameContent = <LoadingDialog />;
    } else if (isSuccessUpdateNameRequestAPI) {
      updateNameContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            refetchUserProfileAPI();
            setApiStateIndex(0);
            onCloseFn();
          }}
          data={{
            title: "Success",
            msg: "Successfully updated!",
          }}
        />
      );
    } else if (updateNameRequestAPIError) {
      setApiStateIndex({
        index: 2,
        msg: updateNameRequestAPIError.data?.errorMessage ?? "Something Went Wrong",
        callBack: () =>
          setApiStateIndex({
            index: 0,
            msg: "",
          }),
      });
    }
  } else if (apiStateIndex.index === 2) {
    updateNameContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          apiStateIndex.callBack();
        }}
        data={{
          title: "Oops!",
          msg: apiStateIndex.msg,
        }}
      />
    );
  } else {
    updateNameContent = null;
  }

  return (
    <Dialog fullWidth={true} open={true}>
      <DialogTitle style={{ paddingLeft: getElementWidth(20) }}>
        <Typography
          style={{
            fontSize: getElementWidth(35),
            textAlign: "left",
            fontWeight: "100",
          }}
        >
          Update Name
        </Typography>
      </DialogTitle>
      <DialogContent dividers style={{ padding: getElementWidth(10) }}>
        <TextField
          style={{
            width: "100%",
            borderRadius: "0",
            height: getElementWidth(100),
            fontSize: getElementWidth(30),
            marginBottom: getElementWidth(50),
            padding: "0px",
          }}
          placeholder="ABCDEF"
          type="text"
          variant="outlined"
          value={userName.value}
          onChange={onNameChanged}
        />
        {/* <div style={{ height: getElementWidth(50) }} /> */}

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            color="primary"
            variant="contained"
            onClick={updateNameBtnClickFn}
            style={{
              fontSize: getElementWidth(17),
              textTransform: "none",
              alignSelf: "center",
              marginBottom: "10px",
            }}
            disableElevation
            disabled={userName.disable}
          >
            Update
          </Button>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCloseFn();
          }}
          color="primary"
        >
          <Typography
            style={{
              textTransform: "none",
              fontSize: getElementWidth(30),
              textAlign: "left",
            }}
          >
            Cancel
          </Typography>
        </Button>
      </DialogActions>
      {updateNameContent}
    </Dialog>
  );
};

export default EditNameDialog;
