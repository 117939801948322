import { Backdrop, Box, CircularProgress, Grid, Tab, Tabs, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useGetPopularListQuery } from "../../services/catalog";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import FeedbackDialog from "../../utils/FeedbackDialog";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";
import ImiSearchResult from "../Search/ImiSearchResult";
import DuelListItem from "./DuelListItem";
import PropTypes from "prop-types";
import ActiveDuelsList from "./ActiveDuelsList";
import ExpiredDuelsList from "./ExpiredDuelsList";
import { useNavigate } from "react-router-dom";
import { ImiPathsUrls } from "../../utils/ImiUtilData";

//#region TAB PANEL

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && (
        <Box>
          <Typography component={"div"} variant={"body1"}>
            {children}
          </Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

//#endregion

const MyDuelsList = ({ openGameFn, token }) => {
  //#region HOOKS & APIS
  const navigate = useNavigate();
  const [value, setValue] = useState(0);

  //#endregion

  //#region UTIL FNS

  const TabPanelClick = (event, newValue) => {
    setValue(newValue);
  };

  const goHomeToOpenTheGameFn = (gameUUID) => {
    navigate(ImiPathsUrls.home, {
      replace: true,
      state: { gameId: gameUUID },
    });
  };

  const getEndDate = (date) => {
    var dateData = new Date(date)
      .toLocaleString("default", {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
        //hour12: true,
        hourCycle: "h12",
      })
      .split(" ");

    return `${dateData[1]} ${dateData[0]}  at ${dateData[2]} ${dateData[3]}`;
  };

  const getShortName = (name) => {
    let firstName = name.split(" ")[0];
    firstName = firstName.length > 8 ? firstName.substring(0, 7) : firstName;
    return firstName;
  };

  const getScoreComparison = (p1Score, p2Score, active) => {
    //let p1Score = item.player_one.score;
    //let p2Score = item.player_two.score;

    let comparison = "";

    if (p1Score > p2Score) {
      comparison = active ? `You lead the duel by ${p1Score - p2Score}` : `You won the duel by ${p1Score - p2Score}`;
    } else if (p1Score < p2Score) {
      comparison = active ? `You trail the duel by ${p2Score - p1Score}` : `You lost the duel by ${p2Score - p1Score}`;
    } else {
      comparison = active ? `The duel is tied` : `The duel was tied`;
    }

    return comparison;
  };

  const _commonFnObj = {
    openGameFn: goHomeToOpenTheGameFn,
    getDateDesFn: getEndDate,
    getNameDesFn: getShortName,
    getScoreDesFn: getScoreComparison,
  };

  //#endregion

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box sx={{ width: "100%", height: getElementHeight(100) }}>
        <Typography
          color="text.primary"
          fontWeight="bold"
          fontSize={getElementWidth(32)}
          style={{
            paddingLeft: getElementWidth(25),
            marginTop: getElementWidth(25),
          }}
        >
          Duels
        </Typography>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Tabs variant="fullWidth" centered textColor="primary" indicatorColor="primary" value={value} onChange={TabPanelClick}>
          <Tab label="Ongoing" {...a11yProps(0)} />
          <Tab label="Expired" {...a11yProps(1)} />
        </Tabs>
      </Box>
      <Box sx={{ width: "100%", hight: "100%" }}>
        <TabPanel value={value} index={0}>
          <ActiveDuelsList token={token} commonFnObj={_commonFnObj} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ExpiredDuelsList token={token} commonFnObj={_commonFnObj} />
        </TabPanel>
      </Box>
      <DummyBottomSpace />
    </Box>
  );
};

export default MyDuelsList;
