import {
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const LoadingDialog = () => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({
    dialogContent: {
      display: "flex",
      alignItems: "center",
    },
    circularProgress: {
      marginRight: "10px",
    },
  }));

  const classes = useStyles();
  //#endregion

  return (
    <Dialog open={true} fullWidth={true}>
      <DialogTitle>Wait...!</DialogTitle>
      <DialogContent dividers className={classes.dialogContent}>
        <CircularProgress className={classes.circularProgress} />
        <Typography> Loading</Typography>
      </DialogContent>
    </Dialog>
  );
};

export default LoadingDialog;
