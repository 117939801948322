import { LockRounded, PlayCircleOutline } from "@mui/icons-material";
import { Box, ImageListItem, Typography } from "@mui/material";
import React from "react";
import { GameModes, ImiCategoryTypes, PromoGameLeaderBoardStatus } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";

export const getTopTitleForPromoGames = (item) => {
  let date = item.additional_info.active_leaderboard ? item.additional_info.current_leaderboard_end_date : item.additional_info.next_leaderboard_start_date;
  let word = item.additional_info.active_leaderboard ? "ends" : "commences";
  var dateData = new Date(date)
    .toLocaleString("default", {
      day: "2-digit",
      month: "short",
      hour: "2-digit",
      minute: "2-digit",
      //hour12: true,
      hourCycle: "h12",
    })
    .split(" ");

  return `Challenge ${word} \n on ${dateData[1]} ${dateData[0]} at ${dateData[2]} ${dateData[3]}`;
};

const ImiImageItem = ({ token, item, type, width, openGameFn, favState, refValue, ...restProps }) => {
  //#region UTIL FNS
  const gameType = item.additional_info.fiji_game_type ?? GameModes.NORMAL;

  // const getTopTitleForPromoGames = () => {
  //   let date = item.additional_info.active_leaderboard ? item.additional_info.current_leaderboard_end_date : item.additional_info.next_leaderboard_start_date;
  //   let word = item.additional_info.active_leaderboard ? "ends" : "commences";
  //   var dateData = new Date(date)
  //     .toLocaleString("default", {
  //       day: "2-digit",
  //       month: "short",
  //       hour: "2-digit",
  //       minute: "2-digit",
  //       //hour12: true,
  //       hourCycle: "h12",
  //     })
  //     .split(" ");

  //   return `Challenge ${word} \n on ${dateData[1]} ${dateData[0]} at ${dateData[2]} ${dateData[3]}`;
  // };
  //#endregion

  //#region  TOP BAR CONTENT
  let topRedBarContent = null;

  if (type === ImiCategoryTypes.PROMO_GAME) {
    topRedBarContent = (
      <Box
        sx={{
          height: getElementWidth(90),
          width: { width },
          backgroundColor: "rgba(207, 59, 55, 255)",
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          component={"span"}
          style={{
            fontSize: getElementWidth(25),
            fontWeight: "500",
            fontFamily: "Inter",
            color: "rgba(255,255,255,255)",
            paddingLeft: getElementWidth(5),
            paddingRight: getElementWidth(5),
            whiteSpace: "pre-line",
          }}
        >
          {getTopTitleForPromoGames(item)}
        </Typography>
      </Box>
    );
  }
  //#endregion

  //#region THUMBNAIL CONTENT
  let thumbnailContent = (
    <ImageListItem ref={refValue} {...restProps} style={{ marginRight: getElementWidth(15) }}>
      <input
        type="image"
        src={item.item_cover_image}
        alt={item.item_name}
        onClick={() => {
          if (type === ImiCategoryTypes.GAME || type === ImiCategoryTypes.BETA_GAME || type === ImiCategoryTypes.FAVORITE) {
            if (item.item_url) {
              openGameFn(item.item_url, item.additional_info.uuid, true);
            } else {
              console.log("Deactivated");
            }
          } else if (type === ImiCategoryTypes.PROMO_GAME) {
            let isPromoGameLeaderBoardActive = item.additional_info.active_leaderboard;
            let gameUrl = isPromoGameLeaderBoardActive ? item.item_url : item.additional_info.teaser_game_url;
            openGameFn(gameUrl, item.additional_info.uuid, true, {
              type: gameType,
              associatedPromoGameID: null,
              showPromoIntroScreen: true,
              promoGameLeaderBoardStatus: isPromoGameLeaderBoardActive ? PromoGameLeaderBoardStatus.active : PromoGameLeaderBoardStatus.notActive,
            });
          } else {
            openGameFn(item.item_url);
          }
        }}
        height="100%"
        width={width}
      />
      {type === ImiCategoryTypes.VIDEO && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            msTransform: "translate(-50%, -50%)",
          }}
        >
          <PlayCircleOutline fontSize={"large"} color="primary.light" />
        </Box>
      )}
      {type === ImiCategoryTypes.GAME && !item.item_url && (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            msTransform: "translate(-50%, -50%)",
          }}
        >
          <LockRounded fontSize={"large"} color="primary.light" />
        </Box>
      )}
    </ImageListItem>
  );
  //#endregion

  //#region BOTTOM BAR CONTENT
  let bottomRedBarContent = null;

  if (type === ImiCategoryTypes.PROMO_GAME && gameType === GameModes.SPOT) {
    bottomRedBarContent = (
      <Box
        sx={{
          height: getElementWidth(70),
          width: { width },
          backgroundColor: "rgba(207, 59, 55, 255)",
          justifyContent: "center",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          component={"span"}
          style={{
            fontSize: getElementWidth(20),
            fontWeight: "700",
            fontFamily: "Inter",
            color: "rgba(255,255,255,255)",
            whiteSpace: "pre-line",
          }}
        >
          {`at ${item.item_description}`}
        </Typography>
      </Box>
    );
  }
  //#endregion

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      {topRedBarContent}
      {thumbnailContent}
      {bottomRedBarContent}
    </Box>
  );
};

export default ImiImageItem;
