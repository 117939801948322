import { Box, ImageList, ImageListItem, Skeleton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { Fragment } from "react";
import { useGetItemListQuery } from "../../services/catalog";
import { GameModes, ImiCategoryTypes, ImiPathsUrls } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";
import ImiImageItem from "./ImiImageItem";

function getCardDimensions(type, Y) {
  if (type === ImiCategoryTypes.PROMO_GAME) {
    return {
      height: getElementWidth(500),
      width: getElementWidth(360),
    };
  } else {
    return {
      height: getElementWidth(250),
      width: getElementWidth(180),
    };
  }
}

const ImiImageList = ({ token, id, title, type, openGameFn, favGameList, socketData }) => {
  //#region STYLES
  const styleConfig = {
    skelton: { marginRight: getElementWidth(15) },
  };

  //#endregion

  //#region HOOKS & APIS

  const nextPageRef = useRef(0);
  const [page, setPage] = useState(nextPageRef.current);
  const pageCountRef = useRef(0);
  const gameListRef = useRef([]);

  const {
    isLoading: isLoadingGetGameListAPI,
    data: getGameListAPIResponse,
    error: getGameListAPIError,
    refetch,
  } = useGetItemListQuery({
    id: id,
    page: page,
    size: 10,
  });

  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        //console.log(nextPageRef.current + " " + pageCountRef.current);
        if (nextPageRef.current < pageCountRef.current) {
          setPage(nextPageRef.current);
        }
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (socketData) {
      if ((socketData.type === "GAME_LEADERBOARD_START" || socketData.type === "GAME_LEADERBOARD_END") && socketData.location === ImiPathsUrls.home) {
        if (socketData.data.catalog_list[0] === id) {
          // nextPageRef.current = 0;
          // gameListRef.current = [];
          // setTimeout(() => {
          //   refetch();
          //   console.log("Delay");
          // }, 5000);
          window.location.href += "";
        }
      }
    }
  }, [socketData]);

  //#endregion

  //#region IMAGE LIST API CONTENT
  const dimensions = getCardDimensions(type);
  const rowHeight = dimensions.height;
  const rowWidth = dimensions.width;

  let imageListAPIContent;

  if (isLoadingGetGameListAPI) {
    imageListAPIContent = (
      <div
        style={{
          display: "flex",
        }}
      >
        {[
          <ImageListItem key={0}>
            <Skeleton variant="rect" style={{ ...styleConfig.skelton }} width={rowWidth} height={rowHeight} />
          </ImageListItem>,
          <ImageListItem key={1}>
            <Skeleton variant="rect" style={{ ...styleConfig.skelton }} width={rowWidth} height={rowHeight} />
          </ImageListItem>,
          <ImageListItem key={2}>
            <Skeleton variant="rect" style={{ ...styleConfig.skelton }} width={rowWidth} height={rowHeight} />
          </ImageListItem>,
          <ImageListItem key={3}>
            <Skeleton variant="rect" style={{ ...styleConfig.skelton }} width={rowWidth} height={rowHeight} />
          </ImageListItem>,
          <ImageListItem key={4}>
            <Skeleton variant="rect" style={{ ...styleConfig.skelton }} width={rowWidth} height={rowHeight} />
          </ImageListItem>,
          <ImageListItem key={5}>
            <Skeleton variant="rect" style={{ ...styleConfig.skelton }} width={rowWidth} height={rowHeight} />
          </ImageListItem>,
        ]}
      </div>
    );
  } else if (getGameListAPIError) {
    imageListAPIContent = <Typography variant="h6">Something went wrong!</Typography>;
  } else {
    if (getGameListAPIResponse?.dataRows) {
      pageCountRef.current = getGameListAPIResponse.totalPages;

      if (nextPageRef.current === getGameListAPIResponse.currentPage) {
        nextPageRef.current = getGameListAPIResponse.currentPage + 1;

        gameListRef.current = [...gameListRef.current, ...getGameListAPIResponse.dataRows];
      }

      //FILTER UNIQUE ITEMS
      //FOR VIDEOS additional_info IS NULL

      let tempGameList = [...new Map(gameListRef.current.map((item, index) => [item.additional_info?.uuid ?? index, item])).values()];

      try {
        if (type === ImiCategoryTypes.PROMO_GAME) {
          for (let i = tempGameList.length - 1; i >= 0; i--) {
            let item = tempGameList[i];

            if (item.additional_info.fiji_game_type === GameModes.SPOT) {
              if (!item.additional_info.current_leaderboard_end_date && !item.additional_info.next_leaderboard_start_date) {
                tempGameList.splice(i, 1);
              }
            }
          }
        }
      } catch (e) {}

      imageListAPIContent = (
        <Box
          style={{
            display: "flex",
          }}
        >
          {tempGameList.map((item, index) => (
            <ImiImageItem
              token={token}
              item={item}
              type={type}
              width={rowWidth}
              openGameFn={openGameFn}
              key={item.additional_info.uuid ?? index}
              //key={index}
              favState={favGameList.includes(item.additional_info?.uuid)}
              refValue={pageCountRef.current > 1 && tempGameList.length === index + 1 ? lastElementRef : null}
            />
          ))}
        </Box>
      );
    } else {
      imageListAPIContent = <Typography variant="h6">Something went wrong!</Typography>;
    }
  }
  //#endregion

  return (
    <Fragment>
      <Box
        style={{
          height: getElementWidth(90),
          display: "flex",
          alignItems: "flex-end",
          paddingBottom: getElementWidth(13),
          fontSize: "0px",
        }}
      >
        <Typography
          color="text.primary"
          style={{
            marginLeft: getElementWidth(15),
            fontSize: getElementWidth(25),
            fontWeight: "bold",
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
          overflow: "hidden",
          fontSize: "0px",
        }}
      >
        <ImageList
          style={{
            flexWrap: "nowrap",
            width: "100%",
            // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
            transform: "translateZ(0)",
          }}
          cols={0}
          rowHeight={rowHeight}
        >
          {imageListAPIContent}
        </ImageList>
      </Box>
    </Fragment>
  );
};

export default ImiImageList;
