import React, { Fragment, useEffect, useState } from "react";
import { useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { homeActions } from "../../store";
import { DATA_KEY } from "../../utils/ImiUtilData";
import ImiSearchBar from "./ImiSearchBar";
import ImiSearchList from "./ImiSearchList";
import ImiSearchPopular from "./ImiSearchPopular";

const ImiSearch = ({ openGameFn }) => {
  //#region HOOKS & APIS
  const userToken = useSelector((state) => state.home.userToken);

  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState("");

  useLayoutEffect(() => {
    dispatch(homeActions.setActiveSubScreen(null));
  }, [dispatch]);

  useEffect(() => {
    //RETRIEVE DATA ON PAGE REFRESH
    if (userToken === null) {
      let _userData = localStorage.getItem(DATA_KEY);

      if (_userData) {
        //dispatch(homeActions.setUserToken(_userData));
        _userData = JSON.parse(_userData);

        dispatch(homeActions.setUserToken(_userData.token));
        dispatch(
          homeActions.setUserData({
            name: _userData.name,
            image: _userData.image,
          })
        );
      }
    }

    //cleanup function
    return () => {
      if (timeoutHandle) clearTimeout(timeoutHandle);
    };
  }, []);
  //#endregion

  //#region  SEARCH CONTENT
  const closeHandler = () => {
    setSearchTerm("");
  };

  let timeoutHandle;

  //for debounce
  const searchHandler = (value) => {
    if (timeoutHandle) clearTimeout(timeoutHandle);
    //timeoutHandle = setTimeout(callApi.bind(null, value), 1000);
    timeoutHandle = setTimeout(setSearchTerm(value.trim()), 1000);
  };

  let searchContent;

  if (searchTerm.length > 0) {
    searchContent = (
      <ImiSearchList search={searchTerm} openGameFn={openGameFn} />
    );
  } else {
    searchContent = <ImiSearchPopular openGameFn={openGameFn} />;
  }

  //#endregion

  return (
    <Fragment>
      <ImiSearchBar onClose={closeHandler} onSearch={searchHandler} />
      {searchContent}
    </Fragment>
  );
};

export default ImiSearch;
