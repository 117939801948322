import { Box, Button, Dialog, Typography } from "@mui/material";
import React from "react";
import { getElementWidth } from "../../utils/ScreenManager";

export default function GameOverTimeOutDialog({leaveGameFn}) {
  return (
    <Dialog
      //fullScreen
      fullWidth={true}
      open={true}
      PaperProps={{
        style: {
          backgroundColor: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: "#FFFFFF",
          boxShadow: " 0px -5px 25px 7px rgba(0, 0, 0, 0.13)",
          height: "25vh",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              fontSize: getElementWidth(36),
              fontWeight: "400",
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.87)",
              fontStyle: "normal",
            }}
          >
            Oops, you don't have enough
          </Typography>
          <Typography
            sx={{
              fontSize: getElementWidth(36),
              fontWeight: "400",
              fontFamily: "Roboto",
              color: "rgba(0, 0, 0, 0.87)",
              fontStyle: "normal",
            }}
          >
            time to enter the challenge.
          </Typography>
        </Box>
        <br />
        <Box>
          <Button
            sx={{
              fontSize: getElementWidth(30),
              fontFamily: "Roboto",
              fontWeight: "500",
              paddingRight: getElementWidth(5),
              paddingLeft: getElementWidth(5),
              backgroundColor: "rgb(245,245,245)",
              color: "rgba(0, 0, 0, 0.87)",
              borderRadius: "4px",
              boxShadow:
                "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
            }}
            onClick={leaveGameFn}
          >
            LEAVE GAME
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
}
