import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { useJoinChallengeQuery } from "../../../services/challengesAPI";
import FeedbackDialog from "../../../utils/FeedbackDialog";

const JoinP2PDuelDialog = ({ closeFn, challengeCode }) => {
  //#region HOOKS
  const userToken = useSelector((state) => state.home.userToken);
  //#endregion

  //#region JOIN CHALLENGE API STATES

  const { isSuccess, data, error, isLoading } = useJoinChallengeQuery({ userToken: userToken, code: challengeCode });

  let joinChallengeContent;

  if (isLoading) {
    joinChallengeContent = (
      <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <CircularProgress />
      </Backdrop>
    );
  } else if (error) {
    joinChallengeContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          closeFn();
        }}
        data={{
          title: "Oops...!",
          msg: error.errorMessage ?? "Join Challenge Failed",
        }}
      />
    );
  } else if (isSuccess) {
    joinChallengeContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          closeFn();
        }}
        data={{
          title: "Cheers",
          msg: "Good luck with the duel",
        }}
      />
    );
  }

  //#endregion

  return <>{joinChallengeContent}</>;
};

export default JoinP2PDuelDialog;
