import { Box, Button, Dialog, DialogActions, DialogTitle, Grid, Typography } from "@mui/material";
import React from "react";
import { getElementWidth } from "../../../utils/ScreenManager";

export default function PaymentConsentDialog({ title, paymentConsentYes, paymentConsentNo }) {
  const paymentNo = () => {
    paymentConsentNo();
  };
  const paymentYes = () => {
    paymentConsentYes();
  };

  return (
    <Dialog
      fullScreen
      open={true}
      fullWidth={true}
      PaperProps={{
        style: {
          backgroundColor: "#FFFFFF00",
          // position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          paddingLeft: getElementWidth(35),
          paddingRight: getElementWidth(35),
        },
      }}
    >
      <Box
        sx={{
          // position: "absolute",
          height: "35vh",
          //backgroundColor: "red",
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: 50,
        }}
      >
        <img height={"100%"} alt="giftImg" src="/images/giftImg.png" />
      </Box>
      <Box
        sx={{
          display: "flex",
          width: "100%",
          backgroundColor: "#FFFFFF",
          boxShadow: " 0px -5px 25px 7px rgba(0, 0, 0, 0.13)",
          height: "43vh",
          flexDirection: "column",
          marginTop: "-8vh",
        }}
      >
        <Box
          sx={{
            height: "3vh",
            //backgroundColor: "red",
            width: "100%",
          }}
        ></Box>
        <Box
          sx={{
            height: "8vh",
            //backgroundColor: "yellow",
            width: "100%",
            zIndex: 100,
          }}
        >
          <Typography
            style={{
              fontSize: "18px",
              textAlign: "center",
              fontWeight: "bold",
            }}
            color="black"
          >
            {title}
          </Typography>
        </Box>
        <Box
          sx={{
            //backgroundColor: "yellow",
            width: "100%",
            paddingLeft: getElementWidth(10),
            paddingRight: getElementWidth(10),
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Box>
            <Button
              onClick={paymentYes}
              autoFocus
              style={{
                background: `url("/images/enterChallengeBtn.png") no-repeat center/100%`,
                backgroundRepeat: "no-repeat",
                whiteSpace: "nowrap",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                height: "12vh",
                borderRadius: "4px",
                width: "100%",
              }}
            ></Button>
          </Box>
          <Box
            sx={{
              height: "2vh",
              //backgroundColor: "red",
              width: "100%",
            }}
          ></Box>
          <Box>
            <Button
              onClick={paymentNo}
              autoFocus
              style={{
                background: `url("/images/keepPracticingBtn.png") no-repeat center/100%`,
                backgroundRepeat: "no-repeat",
                whiteSpace: "nowrap",
                backgroundSize: "contain",
                backgroundPosition: "center center",
                height: "9vh",
                borderRadius: "4px",
                width: "100%",
              }}
            ></Button>
          </Box>
          <Box
            sx={{
              height: "2vh",
              //backgroundColor: "red",
              width: "100%",
            }}
          ></Box>
          <Box
            sx={{
              height: "8vh",
              //backgroundColor: "yellow",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Typography
              style={{
                fontSize: "12px",
                textAlign: "center",
                fontWeight: "bold",
              }}
              color="black"
            >
              By entering the challenge, you are accepting to our "Terms and
              Conditions".
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}
