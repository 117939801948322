import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import React, { useCallback, useRef, useState } from "react";
import { useGetGameWiseAllExpiredChallengesQuery } from "../../../services/challengesAPI";
import { getElementWidth } from "../../../utils/ScreenManager";
import P2PDuelsListItem from "./P2PDuelsListItem";

const GameWiseExpiredP2PDuelsList = ({ token, gameUUID }) => {
  //#region APIS && HOOKS
  const nextPageRef = useRef(0);
  const [page, setPage] = useState(nextPageRef.current);
  const pageCountRef = useRef(0);
  const challengeListRef = useRef([]);
  const { data, error, isLoading, refetch } =
    useGetGameWiseAllExpiredChallengesQuery({
      uuid: gameUUID,
      userToken: token,
      page: page,
      size: 5,
    });
  //#endregion

  //#region OBSERVER FN
  const observer = useRef();
  const lastElementRef = useCallback((node) => {
    if (observer.current) observer.current.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        //console.log(nextPageRef.current + " " + pageCountRef.current);
        if (nextPageRef.current < pageCountRef.current) {
          setPage(nextPageRef.current);
        }
      }
    });
    if (node) observer.current.observe(node);
  }, []);
  //#endregion

  //#region ACTIVE CHALLENGES LIST API CONTENT
  let expiredDuelListContent = null;

  if (isLoading) {
    expiredDuelListContent = (
      <Backdrop
        open={true}
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress />
      </Backdrop>
    );
  } else if (error) {
    expiredDuelListContent = (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          width: "100%",
          minHeight: `calc(100vh - ${getElementWidth(450) + "px"})`,
        }}
      >
        <Typography
          sx={{
            fontSize: getElementWidth(30),
            fontWeight: "700",
            fontFamily: "Inter",
            whiteSpace: "pre-line",
          }}
        >
          {error.errorMessage ?? "Something Went Wrong"}
        </Typography>
      </Box>
    );
  } else if (data) {
    try {
      if (data.dataRows.length > 0) {
        pageCountRef.current = data.totalPages;

        if (nextPageRef.current === data.currentPage) {
          nextPageRef.current = data.currentPage + 1;
          challengeListRef.current = [
            ...challengeListRef.current,
            ...data.dataRows,
          ];
        }
        expiredDuelListContent = challengeListRef.current.map((item, index) => {
          return (
            <Grid
              item
              xs={12}
              key={index}
              ref={
                pageCountRef.current > 1 &&
                challengeListRef.current.length === index + 1
                  ? lastElementRef
                  : null
              }
            >
              <P2PDuelsListItem item={item} active={false} />
            </Grid>
          );
        });
      } else {
        expiredDuelListContent = (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              width: "100%",
              minHeight: `calc(100vh - ${getElementWidth(450) + "px"})`,
            }}
          >
            <Typography
              sx={{
                fontSize: getElementWidth(30),
                fontWeight: "700",
                fontFamily: "Inter",
                whiteSpace: "pre-line",
                color: "black",
              }}
            >
              No Past duels.
            </Typography>
          </Box>
        );
      }
    } catch (error) {
      expiredDuelListContent = (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            width: "100%",
            minHeight: `calc(100vh - ${getElementWidth(450) + "px"})`,
          }}
        >
          <Typography
            sx={{
              fontSize: getElementWidth(30),
              fontWeight: "700",
              fontFamily: "Inter",
              whiteSpace: "pre-line",
              color: "black",
            }}
          >
            Something Went Wrong!
          </Typography>
        </Box>
      );
    }
  }
  //#endregion

  return <>{expiredDuelListContent}</>;
};

export default GameWiseExpiredP2PDuelsList;
