import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useActivateFreeSubscriptionMutation, useCheckForFreeSubscriptionQuery } from "../../../services/paymentAPI";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import { GameModes } from "../../../utils/ImiUtilData";
import LoadingDialog from "../../../utils/LoadingDialog";

const FreeSubscriptionCheckDialog = ({ activeGameModeData, closeFn }) => {
  //#region COMMON HOOKS
  const token = useSelector((state) => state.home.userToken);
  const uuid = useSelector((state) => state.home.activeGameUUID);
  //#endregion

  //#region APIS
  const [apiStateIndex, setAPIStateIndex] = useState(0);

  const {
    isLoading: isLoadingCheckForFreeSubscriptionAPI,
    isSuccess: isSuccessCheckForFreeSubscriptionAPI,
    data: checkForFreeSubscriptionAPIData,
    error: checkForFreeSubscriptionAPIError,
  } = useCheckForFreeSubscriptionQuery({
    userToken: token,
    gameUUID: activeGameModeData.type === GameModes.PRACTICE ? activeGameModeData.associatedPromoGameID : uuid,
  });

  const [
    activateFreeSubscription,
    {
      isLoading: isLoadingActivateFreeSubscriptionAPI,
      isSuccess: isSuccessActivateFreeSubscriptionAPI,
      data: activateFreeSubscriptionAPIData,
      error: activateFreeSubscriptionAPIError,
    },
  ] = useActivateFreeSubscriptionMutation();

  let apiStateContent = null;

  if (apiStateIndex === 0) {
    if (isLoadingCheckForFreeSubscriptionAPI) {
      apiStateContent = <LoadingDialog />;
    } else if (checkForFreeSubscriptionAPIError) {
      apiStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            closeFn(false);
          }}
          data={{
            title: "Oops!",
            msg: checkForFreeSubscriptionAPIError.data?.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    } else if (isSuccessCheckForFreeSubscriptionAPI) {
      if (checkForFreeSubscriptionAPIData) {
        setAPIStateIndex(1);
      } else {
        setTimeout(() => {
          closeFn(false);
        }, 100);
      }
    }
  } else if (apiStateIndex === 1) {
    apiStateContent = (
      <FeedbackDialog
        open={true}
        onClose={() => {
          setAPIStateIndex(2);
          activateFreeSubscription({
            userToken: token,
            gameUUID: activeGameModeData.type === GameModes.PRACTICE ? activeGameModeData.associatedPromoGameID : uuid,
          });
        }}
        data={{
          title: "Congratulations!",
          msg: "You have a free subscription",
        }}
      />
    );
  } else if (apiStateIndex === 2) {
    if (isLoadingActivateFreeSubscriptionAPI) {
      apiStateContent = <LoadingDialog />;
    } else if (activateFreeSubscriptionAPIError) {
      apiStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            closeFn(false);
          }}
          data={{
            title: "Oops!",
            msg: activateFreeSubscriptionAPIError.data?.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    } else if (isSuccessActivateFreeSubscriptionAPI) {
      let title = "";
      let msg = "";
      let callbackFn = null;
      if (activateFreeSubscriptionAPIData.subscription_success) {
        title = "Success!";
        msg = "You’re successfully subscribed for free";
        callbackFn = () => closeFn(true);
      } else {
        title = "Sorry!";
        msg = "Failed activating the free subscription";
        callbackFn = () => closeFn(false);
      }

      apiStateContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            callbackFn();
          }}
          data={{
            title: title,
            msg: msg,
          }}
        />
      );
    }
  }
  //#endregion

  return <>{apiStateContent}</>;
};

export default FreeSubscriptionCheckDialog;
