export const baseUrl = "https://api.imigames.io:443/api/v1";
export const webSocketUrl = "wss://wss.imigames.io/websocket";

export const googleWebClientID = "489863039345-c4iev1ukgobtdaqe7m25eh9075rk12pg.apps.googleusercontent.com";
export const fbAppID = "597272911747315";

export const legalContracts = {
  termsCondUrl: "https://imigames.s3.ap-southeast-1.amazonaws.com/html_info/fiji_app/terms_and_conditions/index.html",
  privacyPolicyUrl: "https://imigames.s3.ap-southeast-1.amazonaws.com/html_info/imi_app/privacy_policy/index.html",
};

export const isUnderMaintenance = false;

export const DATA_KEY = "IMI_FIJI_USER_DATA";
export const THEME_DATA = "THEME_DATA";

export const ImiPathsUrls = {
  init: "/init/:id",
  home: "/home",
  game: "/game",
  search: "/search",
  challenges: "/challenges",
  profile: "/profile",
  subs: "/profile/subs",
};

export const ImiCategoryTypes = {
  PROMO_GAME: "PROMO_GAME",
  FAVORITE: "FAVORITE",
  GAME: "GAME",
  VIDEO: "VIDEO",
  IMAGE: "IMAGE",
  BETA_GAME: "BETA_GAME",
  UNKNOWN: "UNKNOWN",
};

export const GameModes = {
  SPOT: "SPOT",
  COMPETITION: "COMPETITION",
  PRACTICE: "PRACTISE",
  NORMAL: "NORMAL",
};

export const PromoGameLeaderBoardStatus = {
  active: "ACTIVE", //PROMO GAME
  notActive: "NOT_ACTIVE", //PROMO GAME
  notAvailable: "NOT_AVAILABLE", //NOT A PROMO
};

export const IMIThemeMode = {
  LIGHT: "light",
  DARK: "dark",
};

export const COMMON_DATA = {
  msg: {},
  fijiData: {
    phoneNumLength: 7,
    zipCode: 679,
  },
};

export const SubScreens = {
  MY_SUBS: "my subscriptions",
  IN_GAME_LOGIN: "in game login",
};
