import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";

export default function P2PDuelsListItem({ item, active }) {
  //#region UTIL FNS
  const getEndDate = () => {
    let date = item.end_time;
    var dateData = new Date(date)
      .toLocaleString("default", {
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
        //hour12: true,
        hourCycle: "h12",
      })
      .split(" ");

    let formattedDate = `${dateData[1]} ${dateData[0]} ${dateData[2]} ${dateData[3]}`;

    return active ? `This duel will remain live till ${formattedDate}` : `Ended on ${formattedDate}`;
  };

  const getShortName = (name) => {
    let firstName = name.split(" ")[0];
    firstName = firstName.length > 8 ? firstName.substring(0, 7) : firstName;
    return firstName;
  };

  const getScoreComparison = () => {
    let p1Score = item.player_one.score;
    let p2Score = item.player_two.score;

    let comparison = "";

    if (p1Score > p2Score) {
      comparison = active ? `You lead the duel by ${p1Score - p2Score}` : `You won the duel by ${p1Score - p2Score}`;
    } else if (p1Score < p2Score) {
      comparison = active ? `You trail the duel by ${p2Score - p1Score}` : `You lost the duel by ${p2Score - p1Score}`;
    } else {
      comparison = active ? `The duel is tied` : `The duel was tied`;
    }

    return comparison;
  };

  //#endregion

  return (
    <Box
      sx={{
        background: "rgba(0, 0, 0, 0.04)",
        borderRadius: "1.1vh",
        height: getElementHeight(380),
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        marginBottom: "10px",
      }}
    >
      <Box
        sx={{
          height: getElementHeight(120),
          width: "100%",
        }}
      >
        <Grid container spacing={3} flexDirection="row" alignItems="center" justifyContent={"space-evenly"}>
          <Grid item>
            <Box
              sx={{
                height: getElementHeight(100),
                width: getElementHeight(100),
                borderRadius: "50%",
              }}
            >
              <img style={{ backgroundColor: "transparent", borderRadius: "50%" }} height={getElementHeight(100)} width={getElementHeight(100)} alt="P1" src={item.player_one.image} />
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                height: getElementHeight(100),
                width: getElementHeight(100),
                width: "auto",
                borderRadius: "50%",
              }}
            >
              <img style={{ backgroundColor: "transparent", borderRadius: "50%" }} height={getElementHeight(100)} width={getElementHeight(100)} alt="P2" src={item.player_two.image} />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box
        sx={{
          height: getElementWidth(50),
          width: "100%",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: getElementWidth(35),
            color: "rgba(0, 0, 0, 1)",
            textAlign: "center",
          }}
        >
          {`${getShortName(item.player_one.name)}: ${item.player_one.score} | ${getShortName(item.player_two.name)}: ${item.player_two.score}`}
        </Typography>
      </Box>
      <Box
        sx={{
          height: getElementWidth(50),
        }}
      >
        <Typography
          style={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: getElementWidth(28),
            color: "rgba(0, 0, 0, 1)",
            textAlign: "center",
          }}
          noWrap={true}
        >
          {getScoreComparison()}
        </Typography>
      </Box>
      <Box
        sx={{
          height: getElementWidth(50),
          textAlign: "center",
        }}
      >
        <Typography
          style={{
            fontFamily: "Inter",
            fontWeight: "400",
            fontSize: getElementWidth(24),
            color: "rgba(0, 0, 0, 1)",
          }}
          noWrap={true}
        >
          {getEndDate()}
        </Typography>
      </Box>
    </Box>
  );
}
