import { Box, Dialog, DialogContent, Typography } from "@mui/material";
import React from "react";
import { getElementWidth } from "./ScreenManager";

export default function PageUnderConstruction() {
  return (
    <Dialog
      open={true}
      fullScreen={true}
      PaperProps={{
        style: {
          backgroundImage: `url("/images/maintenance.png")`,
          boxShadow: "none",
          backgroundRepeat: "no-repeat",
          whiteSpace: "nowrap",
          backgroundSize: "cover",
          backgroundPosition: "center center",
        },
      }}
    >
      <DialogContent style={{ display: "flex", alignItems: "center", flexDirection: "column", justifyContent: "center", backgroundColor: "rgba(255, 255, 255,0.5)" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: getElementWidth(150),
            height: getElementWidth(150),
            borderRadius: "50%",
            backgroundColor: "white",
            boxSizing: "border-box",
          }}
        >
          <Box
            style={{
              backgroundImage: `url(/images/imiLogo.png)`,
              backgroundRepeat: "no-repeat",
              backgroundSize: "cover",
              backgroundPosition: "center center",
              borderRadius: "50%",
              zIndex: "1",
              width: getElementWidth(100),
              height: getElementWidth(100),
              padding: getElementWidth(10),
              boxSizing: "border-box",
            }}
          />
        </Box>
        <Box style={{ height: "3vh" }} />
        <Typography style={{ textAlign: "center", fontWeight: "700", fontSize: getElementWidth(42), color: "black", fontFamily: "Poppins" }}>
          IMI is getting a major
          <br />
          <span style={{ color: "rgba(206, 60, 54,255)", fontWeight: "700", fontSize: getElementWidth(42) }}>facelift!</span>
          <br />
          We will be back with
          <br />
          new exciting games soon.
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
