import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getUserTokenFB: builder.mutation({
      query: (fbToken) => ({
        url: `/user/signup/fb?token=${fbToken}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getUserTokenGoogle: builder.mutation({
      query: (googleToken) => ({
        url: `/user/signup/google_sign_in?token=${googleToken}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    initiateOTPLogin: builder.mutation({
      query: ({ phoneNum }) => ({
        url: `/user/otp`,
        method: "POST",
        headers: {
          "x-platform": "vodafone-fiji",
        },
        body: {
          type: "VODAFONE-FIJI",
          msisdn: phoneNum,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    confirmOTPLogin: builder.mutation({
      query: ({ otp, refID }) => ({
        url: `/user/otp/confirmation`,
        method: "POST",
        headers: {
          "x-platform": "vodafone-fiji",
        },
        body: {
          type: "VODAFONE-FIJI",
          reference_id: refID,
          otp: otp,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getUserProfile: builder.mutation({
      query: (userToken) => ({
        url: `/user/user`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    updateUserName: builder.mutation({
      query: ({ userToken, userName }) => ({
        url: `/user/update`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          "x-platform": "vodafone-fiji",
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          name: userName,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    updateUserImage: builder.mutation({
      query: ({ userToken, image }) => ({
        url: `/user/upload/photo`,
        method: "POST",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "x-platform": "web",
        },
        body: image,
      }),
      transformResponse: (response) => response?.data,
    }),
    checkUserFreeSubscriptionChallengeEligibility: builder.query({
      query: ({ userToken, gameUUID }) => ({
        url: `/lg/p2pchallenge/free-reward/eligibility/${gameUUID}`,
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
          "x-platform": "vodafone-fiji",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetUserTokenFBMutation,
  useGetUserTokenGoogleMutation,
  useInitiateOTPLoginMutation,
  useConfirmOTPLoginMutation,
  useGetUserProfileMutation,
  useUpdateUserNameMutation,
  useUpdateUserImageMutation,
  useCheckUserFreeSubscriptionChallengeEligibilityQuery,
} = userApi;
export default userApi;
