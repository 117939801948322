import { Backdrop, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, IconButton, Radio, RadioGroup, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useCreateNewChallengeMutation } from "../../../services/challengesAPI";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import { getElementWidth } from "../../../utils/ScreenManager";
import P2PDuelShareDialog from "./P2PDuelShareDialog";

const timeOptionsArray = [
  {
    key: 0,
    label: "02 Hour Duel",
    offset: 2,
  },
  {
    key: 1,
    label: "12 Hour Duel",
    offset: 12,
  },
];

export default function CreateNewP2PDuel({ token, gameUUID, onCloseFn }) {
  //#region API && HOOKS
  const [radioGroupValue, setRadioGroupValue] = useState(0);

  const [contentStateIndex, setContentStateIndex] = useState(0);
  const [createNewP2PChallenge, { isLoading, isSuccess, data, error }] = useCreateNewChallengeMutation();

  //#endregion

  //#region ONCHANGE FN
  const handleChange = (event) => {
    setRadioGroupValue(event.target.value);
  };

  function addHours(numOfHours, date = new Date()) {
    date.setTime(date.getTime() + numOfHours * 60 * 60 * 1000);

    return date;
  }

  const getNewP2PChallengeEndTime = (offset) => {
    let newDate = addHours(offset);

    //2022-12-28 12:00:00

    var dateOptions = { year: "numeric", month: "2-digit", day: "2-digit" };
    var timeOptions = { hour12: false };

    let dateArray = newDate.toLocaleDateString("en", dateOptions).split("/");
    //console.log("Date:>>" + newDate.toLocaleDateString("en", dateOptions).replace(/[/]/g, "-") + " " + newDate.toLocaleTimeString("en", timeOptions));

    return `${dateArray[2]}-${dateArray[0]}-${dateArray[1]} ${newDate.toLocaleTimeString("en", timeOptions)}`;
  };

  const createNewChallengeDialogCloseFn = () => {
    setContentStateIndex(0);
    onCloseFn();
  };
  //#endregion

  //#region CONTENT STATES

  let dialogContent;

  if (contentStateIndex === 0) {
    dialogContent = (
      <Dialog
        fullWidth={true}
        open={true}
        onClose={createNewChallengeDialogCloseFn}
        PaperProps={{
          style: {
            justifyContent: "center",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px -5px 25px 7px rgba(0, 0, 0, 0.13)",
          },
        }}
      >
        <DialogTitle sx={{ justifyContent: "left" }}>
          <Typography
            style={{
              fontSize: getElementWidth(35),
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "300",
              color: "#000000",
            }}
          >
            Start a Duel
          </Typography>
        </DialogTitle>
        <Divider style={{ background: "black" }} variant="fullWidth" />
        <DialogContent
          dividers
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: getElementWidth(35),
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: "300",
            color: "#000000",
          }}
        >
          <Box>
            <RadioGroup aria-label="Duel" name="Duel" value={radioGroupValue} onChange={handleChange}>
              {timeOptionsArray.map((item) => {
                return <FormControlLabel value={item.key} key={item.key} control={<Radio />} label={item.label} />;
              })}
            </RadioGroup>
          </Box>
        </DialogContent>
        <Divider style={{ background: "black" }} variant="fullWidth" />
        <DialogActions sx={{ justifyContent: "right" }}>
          <Button
            onClick={() => {
              let _endTime = getNewP2PChallengeEndTime(timeOptionsArray[radioGroupValue].offset);
              setContentStateIndex(1);
              createNewP2PChallenge({ uuid: gameUUID, userToken: token, endDate: _endTime });
            }}
            sx={{
              color: "red",
              fontFamily: "Roboto",
              fontStyle: "normal",
              fontWeight: "500",
            }}
          >
            Start
          </Button>
        </DialogActions>
      </Dialog>
    );
  } else if (contentStateIndex === 1) {
    if (isLoading) {
      dialogContent = (
        <Backdrop open={true} sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress />
        </Backdrop>
      );
    } else if (error) {
      dialogContent = (
        <FeedbackDialog
          open={true}
          onClose={() => {
            createNewChallengeDialogCloseFn();
          }}
          data={{
            title: "Oops!",
            msg: error.errorMessage ?? "Something Went Wrong",
          }}
        />
      );
    } else if (isSuccess) {
      //console.log(data);
      dialogContent = <P2PDuelShareDialog linkData={{ gameUUID: gameUUID, duelToken: data.token }} onCloseFn={createNewChallengeDialogCloseFn} />;
    }
  }

  //#endregion

  return <>{dialogContent}</>;
}
