import { Facebook, StayCurrentPortrait } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetUserProfileMutation, useGetUserTokenFBMutation, useGetUserTokenGoogleMutation } from "../../services/user";
import { homeActions } from "../../store";
import { getElementWidth } from "../ScreenManager";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import LoadingDialog from "../LoadingDialog";
import FeedbackDialog from "../FeedbackDialog";
import { makeStyles } from "@mui/styles";
import { DATA_KEY, fbAppID, ImiPathsUrls } from "../ImiUtilData";
import { useNavigate } from "react-router-dom";
import { useRef } from "react";
import { GoogleLogin } from "@react-oauth/google";

const LoginDialog = ({ onClose, open }) => {
  //#region STYLES
  const useStyles = makeStyles(() => ({}));
  //#endregion

  //#region HOOKS & APIS

  const classes = useStyles();
  //const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const loginToken = useRef(null);

  //USER TOKEN FB
  const [getUserTokenFB, { isLoading: loadingFBTokenAPI, isSuccess: fbTokenAPISuccess, data: fbTokenAPIData, error: fbTokenAPIError }] = useGetUserTokenFBMutation();

  //USER TOKEN FB
  const [getUserTokenGoogle, { isLoading: loadingGoogleTokenAPI, isSuccess: googleTokenAPISuccess, data: googleTokenAPIData, error: googleTokenAPIError }] = useGetUserTokenGoogleMutation();

  //USER PROFILE
  const [getUserProfile, { isLoading: loadingUserProfileAPI, isSuccess: userProfileAPISuccess, data: userProfileAPIData, error: userProfileAPIError }] = useGetUserProfileMutation();

  useEffect(() => {
    if (userProfileAPISuccess) {
      dispatch(homeActions.setUserToken(loginToken.current));
      dispatch(
        homeActions.setUserData({
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
          id: userProfileAPIData.id,
        })
      );

      localStorage.setItem(
        DATA_KEY,
        JSON.stringify({
          token: loginToken.current,
          name: userProfileAPIData.name,
          image: userProfileAPIData.image_url,
          id: userProfileAPIData.id,
        })
      );
      onClose(true);
    }
  }, [userProfileAPISuccess]);
  //#endregion

  //#region UTIL
  const responseFacebook = (response) => {
    //console.log(response);

    if (response.accessToken) {
      setTokenAPIStateIndex(1); //GET FB USER TOKEN
      getUserTokenFB(response.accessToken);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const responseGoogle = (response) => {
    //console.log(response);

    if (response.tokenId) {
      setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
      getUserTokenGoogle(response.tokenId);
    } else {
      setTokenAPIStateIndex(4);
    }
  };

  const legalContracts_BtnClick = () => {
    onClose(false);
    navigate(ImiPathsUrls.profile, {
      replace: true,
    });
  };

  const otpBtnClick = () => {
    dispatch(
      homeActions.setIsOTPLoginDialogActive({
        state: true,
        callbackFn: onClose,
      })
    );
  };

  //#endregion

  //#region USER TOKEN APIS STATES

  const [tokenAPIStateIndex, setTokenAPIStateIndex] = useState(0);

  let tokenAPIStateContent;

  //FB TOKEN
  if (tokenAPIStateIndex === 1) {
    if (loadingFBTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (fbTokenAPIError) {
      tokenAPIStateContent = (
        <FeedbackDialog
          id="FB Login Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "FB Error",
          }}
        />
      );
    } else if (fbTokenAPISuccess) {
      //SAVE TO LOCAL STORAGE
      //dispatch(homeActions.setUserToken(fbTokenData.access_token));
      loginToken.current = fbTokenAPIData.access_token;
      setTokenAPIStateIndex(3);
      getUserProfile(fbTokenAPIData.access_token);
    }
  }
  //GOOGLE TOKEN
  else if (tokenAPIStateIndex === 2) {
    if (loadingGoogleTokenAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (googleTokenAPIError) {
      //console.log(googleTokenDataError.error);

      tokenAPIStateContent = (
        <FeedbackDialog
          id="Google Login Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Google Error",
          }}
        />
      );
    } else if (googleTokenAPISuccess) {
      loginToken.current = googleTokenAPIData.access_token;
      setTokenAPIStateIndex(3);
      getUserProfile(googleTokenAPIData.access_token);
    }
  }
  //USER TOKEN
  else if (tokenAPIStateIndex === 3) {
    if (loadingUserProfileAPI) {
      tokenAPIStateContent = <LoadingDialog />;
    } else if (userProfileAPIError) {
      tokenAPIStateContent = (
        <FeedbackDialog
          id="Token Error Dialog"
          open={true}
          onClose={() => {
            setTokenAPIStateIndex(0);
          }}
          data={{
            title: "Oops!",
            msg: "Error",
          }}
        />
      );
    } else if (userProfileAPISuccess) {
      setTokenAPIStateIndex(0);
    }
  }
  //FEEDBACK DIALOG
  else if (tokenAPIStateIndex === 4) {
    tokenAPIStateContent = (
      <FeedbackDialog
        id="Error Dialog"
        open={true}
        onClose={() => {
          setTokenAPIStateIndex(0);
        }}
        data={{
          title: "Oops!",
          msg: "User Cancelled",
        }}
      />
    );
  } else if (tokenAPIStateIndex === 5) {
    tokenAPIStateContent = null;
  }
  //LOGIN DIALOG
  else {
    tokenAPIStateContent = (
      <Fragment>
        <Dialog fullWidth={true} open={open}>
          <DialogTitle>
            <Typography
              style={{
                fontSize: getElementWidth(35),
                textAlign: "left",
                fontWeight: "100",
              }}
            >
              Please login to continue!
            </Typography>
          </DialogTitle>
          <DialogContent dividers>
            <FacebookLogin
              appId={fbAppID}
              autoLoad={false}
              callback={responseFacebook}
              render={(renderProps) => (
                <Button
                  onClick={renderProps.onClick}
                  style={{
                    backgroundColor: "rgba(75, 111, 167,255)",
                    display: "flex",
                    flexDirection: "row",
                    width: getElementWidth(480),
                    height: getElementWidth(80),
                    //display : 'flex',
                    marginTop: getElementWidth(20),
                    //marginBottom : getElementWidth(20),
                    marginLeft: "auto",
                    marginRight: "auto",
                    justifyContent: "center",
                  }}
                  startIcon={<Facebook sx={{ color: "white" }} />}
                >
                  <Typography color="white" fontSize={getElementWidth(28)} fontWeight="bold" textTransform="none">{`Facebook`}</Typography>
                </Button>
              )}
              isMobile={true}
              disableMobileRedirect={true}
            />

            <div
              style={{
                borderRadius: "4px",
                backgroundColor: "#1a73e8",
                display: "flex",
                flexDirection: "row",
                width: getElementWidth(480),
                height: getElementWidth(80),
                marginTop: getElementWidth(20),
                //marginBottom : getElementWidth(20),
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <GoogleLogin
                logo_alignment="center"
                type="standard"
                size="medium"
                shape="rectangular"
                theme="filled_blue"
                onSuccess={(credentialResponse) => {
                  //console.log(credentialResponse);
                  setTokenAPIStateIndex(2); //GET GOOGLE USER TOKEN
                  getUserTokenGoogle(credentialResponse.credential);
                }}
                onError={() => {
                  //console.log("Login Failed");
                  setTokenAPIStateIndex(4);
                }}
              />
            </div>

            <Button
              onClick={otpBtnClick}
              style={{
                display: "flex",
                flexDirection: "row",
                backgroundColor: "rgba(206, 60, 54,255)",
                width: getElementWidth(480),
                height: getElementWidth(80),
                //display : 'flex',
                marginTop: getElementWidth(20),
                //marginBottom : getElementWidth(20),
                marginLeft: "auto",
                marginRight: "auto",
                justifyContent: "center",
              }}
              startIcon={<StayCurrentPortrait sx={{ color: "white" }} />}
            >
              <Typography color="white" fontSize={getElementWidth(28)} fontWeight="bold" textTransform="none">{`OTP`}</Typography>
            </Button>
            <div style={{ height: getElementWidth(40) }}></div>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Typography
                style={{
                  textTransform: "none",
                  fontSize: getElementWidth(20),
                  textAlign: "center",
                  fontWeight: "500",
                }}
              >
                By Logging in, you agree to our&nbsp;
                <span style={{ color: "rgba(206, 60, 54,255)" }} onClick={legalContracts_BtnClick}>
                  Terms and Conditions{" "}
                </span>
                and acknowledge that you have read the
                <span style={{ color: "rgba(206, 60, 54,255)" }} onClick={legalContracts_BtnClick}>
                  {" "}
                  Privacy Policy.
                </span>
              </Typography>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onClose(false);
              }}
              color="primary"
            >
              <Typography style={{ fontSize: getElementWidth(30), textAlign: "left" }}>Cancel</Typography>
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
  //#endregion

  return <Fragment>{tokenAPIStateContent}</Fragment>;
};

export default LoginDialog;
