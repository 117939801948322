import { AccountBox, Home, Search, SportsMmaSharp } from "@mui/icons-material";
import { BottomNavigation, BottomNavigationAction, Slide, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ImiPathsUrls } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";

const HideOnCommand = (props) => {
  const { children, trigger } = props;

  return (
    <Slide appear={false} direction="up" in={trigger}>
      {children}
    </Slide>
  );
};

const ImiBottomNav = (props) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));
  const classes = useStyles();

  const styleConfig = {
    bottomNavBar: {
      width: getElementWidth(720),
      position: "fixed",
      bottom: 0,
      justifyContent: "space-around",
      height: getElementWidth(100),
    },
    label: {
      fontSize: getElementWidth(18),
    },
    icon: {
      fontSize: getElementWidth(45),
    },
  };
  //#endregion

  const location = useLocation();

  useEffect(() => {
    if (location.pathname === ImiPathsUrls.home) {
      setSelectedValue(ImiPathsUrls.home);
    } else if (location.pathname === ImiPathsUrls.search) {
      setSelectedValue(ImiPathsUrls.search);
    } else if (location.pathname === ImiPathsUrls.challenges) {
      setSelectedValue(ImiPathsUrls.challenges);
    } else {
      setSelectedValue(ImiPathsUrls.profile);
    }
  }, [location]);

  const [selectedValue, setSelectedValue] = useState(location.pathname === ImiPathsUrls.profile ? ImiPathsUrls.profile : ImiPathsUrls.home);

  return (
    <HideOnCommand trigger={props.trigger}>
      <BottomNavigation showLabels value={selectedValue} style={{ ...styleConfig.bottomNavBar }}>
        <BottomNavigationAction label={<Typography style={{ ...styleConfig.label }}>Home</Typography>} icon={<Home style={{ ...styleConfig.icon }} />} component={NavLink} to={ImiPathsUrls.home} value={ImiPathsUrls.home} />
        <BottomNavigationAction label={<Typography style={{ ...styleConfig.label }}>Search</Typography>} icon={<Search style={{ ...styleConfig.icon }} />} component={NavLink} to={ImiPathsUrls.search} value={ImiPathsUrls.search} />
        <BottomNavigationAction label={<Typography style={{ ...styleConfig.label }}>Duels</Typography>} icon={<SportsMmaSharp style={{ ...styleConfig.icon }} />} component={NavLink} to={ImiPathsUrls.challenges} value={ImiPathsUrls.challenges} />
        <BottomNavigationAction label={<Typography style={{ ...styleConfig.label }}>Profile</Typography>} icon={<AccountBox style={{ ...styleConfig.icon }} />} component={NavLink} to={ImiPathsUrls.profile} value={ImiPathsUrls.profile} />
      </BottomNavigation>
    </HideOnCommand>
  );
};

export default ImiBottomNav;
