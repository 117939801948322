import { Close, Search } from "@mui/icons-material";
import {
  AppBar,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { getElementWidth } from "../../utils/ScreenManager";

const ImiSearchBar = ({ onClose, onSearch }) => {
  //#region STYLES
  const useStyles = makeStyles((theme) => ({}));

  const classes = useStyles();
  //#endregion

  const [searchStr, setSearchStr] = useState("");

  const searchHandler = (event) => {
    setSearchStr(event.target.value);
    onSearch(event.target.value);
  };

  const closeButtonHandler = () => {
    setSearchStr("");
    onClose();
  };

  return (
    <AppBar position="sticky" elevation={0}>
      <Toolbar
        sx={{
          padding: "0px",
          backgroundColor: "background.default",
        }}
      >
        <OutlinedInput
          style={{
            width: "100%",
            borderRadius: "0",
            marginTop: getElementWidth(15),
            height: getElementWidth(100),
          }}
          placeholder="Search"
          value={searchStr}
          //color="secondary"
          startAdornment={
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          }
          endAdornment={
            <InputAdornment position="end">
              <IconButton aria-label="close" onClick={closeButtonHandler}>
                <Close />
              </IconButton>
            </InputAdornment>
          }
          onChange={searchHandler}
        />
      </Toolbar>
    </AppBar>
  );
};

export default ImiSearchBar;
