import { useScrollTrigger } from "@mui/material";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { GameModes, ImiPathsUrls, PromoGameLeaderBoardStatus } from "../../utils/ImiUtilData";
import FriendShareDialog from "../Game/Share/FriendShareDialog";
import GlobalShareDialog from "../Game/Share/GlobalShareDialog";
import PromoGameIntroScreen from "../Game/PromoGameIntroScreen";
import LoginDialog from "../../utils/LogIn/LoginDialog";
import PaymentConsentDialog from "../Game/Payment/PaymentConsentDialog";
import PaymentDialog from "../Game/Payment/PaymentDialog";
import ImiBottomNav from "./ImiBottomNav";
import ImiTopAppBar from "./ImiTopAppBar";
import GameOverYelpDialog from "../Game/GameOverYelpDialog";
import GameOverTimeOutDialog from "../Game/GameOverTimeOutDialog";
import PromoGameList from "../Game/PromoGameList/PromoGameList";
import PromoGameListConsentDialog from "../Game/PromoGameList/PromoGameListConsentDialog";
import { commonObjContext } from "../../App";
import P2PDuelDialog from "../Game/P2PDuels";
import JoinP2PDuelDialog from "../Game/P2PDuels/JoinP2PDuelDialog";
import OTPLoginDialog from "../../utils/LogIn/OTPLoginDialog";
import { homeActions } from "../../store";
import GameOverChallengeDialog from "../Game/GameOverChallengeDialog";
import CreateNewP2PDuel from "../Game/P2PDuels/CreateNewP2PDuel";
import FreeSubscriptionCheckDialog from "../Game/Payment/FreeSubscriptionCheckDialog";

const ImiAppBars = (props) => {
  //#region HOOKS & APIS

  const navigate = useNavigate();
  const [isNavBarsHidden, setNavBarStatus] = useState(false);
  const location = useLocation();
  const trigger = useScrollTrigger();
  const dispatch = useDispatch();
  const uuid = useSelector((state) => state.home.activeGameUUID);
  const activeGameMode = useSelector((state) => state.home.activeGameMode);
  const socketData = useSelector((state) => state.home.socketData);
  const isOTPLoginDialogActive = useSelector((state) => state.home.isOTPLoginDialogActive);
  const userToken = useSelector((state) => state.home.userToken);
  const gameUUID = useSelector((state) => state.home.activeGameUUID);
  const iframeRef = useContext(commonObjContext).iframeRef;

  const eventListener = (locationParam, event) => {
    if (typeof event.data === "string") {
      //CHECK FOR JSON OBJECT
      try {
        let commandObj = JSON.parse(event.data);
        console.log(commandObj);

        if (commandObj.command === "HIDE_NAV") {
          if (!isNavBarsHidden) {
            setNavBarStatus(true);
          }
        } else if (commandObj.command === "EXIT") {
          navigate(locationParam.state?.from?.pathname || ImiPathsUrls.home, {
            replace: true,
          });
        } else if (commandObj.command === "SUBSCRIBE") {
          if (!openPaymentConsentDialog.state) {
            setOpenPaymentConsentDialog({
              state: true,
              isPracticeMode: activeGameMode.type === GameModes.PRACTICE,
              title: paymentConsentDialogTitles.title1,
            });

            //document.getElementById("gameIframe").contentWindow.EntryPoint.onPauseGame();
            iframeRef.current?.contentWindow.EntryPoint.onPauseGame();
          }
        } else if (commandObj.command === "LOGIN") {
          if (!openLogInDialog) {
            setOpenLogInDialog(true);
          }
        } else if (commandObj.command === "SHARE") {
          setOpenShareDialog({
            state: true,
            data: commandObj.data,
            type: commandObj.type,
          });
        } else if (commandObj.command === "YELP") {
          setOpenGameOverYelpDialogScreen(true);
        } else if (commandObj.command === "PROMO_TIME_OVER") {
          setOpenGameOverTimeOutScreen(true);
        } else if (commandObj.command === "OPEN_PRACTICE_GAME") {
          //TEASER GAME ->  PRACTICE GAME
          navigate(ImiPathsUrls.home, {
            replace: true,
            state: {
              openPracticeGame: true,
              associatedPromoGameID: uuid,
              promoGameLeaderBoardStatus: PromoGameLeaderBoardStatus.notActive,
            },
          });
        } else if (commandObj.command === "SHOW_PROMO_GAME_LIST") {
          if (!openPromoGameConsentDialog) {
            setOpenPromoGameConsentDialog(true);
          }
        } else if (commandObj.command === "FRIEND_CHALLENGE") {
          if (commandObj.type === "OPEN") {
            if (!openFriendChallengeDialog) {
              setOpenFriendChallengeDialog(true);
            }
          } else if (commandObj.type === "JOIN") {
            if (!openJoinChallengeDialog.state) {
              setOpenJoinChallengeDialog({
                state: true,
                data: commandObj.data,
              });
            }
          } else if (commandObj.type === "FREE_SUB_OFFER") {
            if (!openGameOverChallengeDialog) {
              setOpenGameOverChallengeDialog(true);
            }
          }
        }
      } catch (e) {
        console.log("Bridge Error : " + e);
      }
    }
  };

  const eventListenerFnRef = useRef(eventListener);

  useEffect(() => {
    window.removeEventListener("message", eventListenerFnRef.current);
    eventListenerFnRef.current = (event) => eventListener(location, event);
    window.addEventListener("message", eventListenerFnRef.current, false);
  }, [location, activeGameMode]);

  useEffect(() => {
    if (location.pathname === ImiPathsUrls.game) {
      setNavBarStatus(true);
    } else {
      if (openFriendChallengeDialog) {
        setOpenFriendChallengeDialog(false);
      } else if (openPromoGameListDialog) {
        setOpenPromoGameListDialog(false);
      }
      setNavBarStatus(false);
    }
  }, [location]);

  //ON SOCKET DATE RECIEVED
  useEffect(() => {
    if (socketData) {
      if (socketData.type === "GAME_LEADERBOARD_START" && socketData.location === ImiPathsUrls.game) {
        let openPaymentConsentDialogBool = false;

        if (activeGameMode.type === GameModes.SPOT) {
          //IN TEASER
          if (uuid === socketData.data.game_uuid) {
            openPaymentConsentDialogBool = true;
          }
        } else if (activeGameMode.type === GameModes.PRACTICE) {
          //IN PRACTICE GAME
          if (activeGameMode.associatedPromoGameID && activeGameMode.associatedPromoGameID === socketData.data.game_uuid) {
            openPaymentConsentDialogBool = true;
          }
        }

        if (openPaymentConsentDialogBool) {
          //document.getElementById("gameIframe").contentWindow.EntryPoint.onPauseGame();

          try {
            iframeRef.current?.contentWindow.EntryPoint.onPauseGame();
          } catch (e) {}

          setOpenPaymentConsentDialog({
            state: true,
            isPracticeMode: activeGameMode.type === GameModes.PRACTICE,
            title: paymentConsentDialogTitles.title2,
          });
        }
      }
    }
  }, [socketData]);

  //ACTIVATE PROMO INRO SCREEN
  useEffect(() => {
    if ((activeGameMode.type === GameModes.SPOT || activeGameMode.type === GameModes.COMPETITION) && activeGameMode.showPromoIntroScreen) {
      setOpenPromoGameIntermediateScreen(true);
    }
  }, [activeGameMode]);
  //#endregion

  //#region LOGIN DIALOG
  const [openLogInDialog, setOpenLogInDialog] = useState(false);

  const onLogInDialogActionBtnClick = (logInBool = false) => {
    if (logInBool) {
      //document.getElementById("gameIframe").src += "&ingamelogin=true";
      iframeRef.current.src += "&ingamelogin=true";
    } else {
      //document.getElementById("gameIframe").src += "";
      iframeRef.current.src += "";
    }

    setOpenLogInDialog(false);
  };

  let logInDialog = <LoginDialog open={openLogInDialog} onClose={onLogInDialogActionBtnClick} />;

  //#endregion

  //#region OTP LOGIN DIALOG

  const otpLogInDialogBoxCloseFn = (state) => {
    dispatch(homeActions.setIsOTPLoginDialogActive(false));

    if (isOTPLoginDialogActive.callbackFn) {
      isOTPLoginDialogActive.callbackFn();
    }
  };

  let otpLoginDialog = null;

  if (isOTPLoginDialogActive.state) {
    otpLoginDialog = <OTPLoginDialog closeDialogBoxFn={otpLogInDialogBoxCloseFn} />;
  }
  //#endregion

  //#region PAYMENT CONSENT DIALOG BOX

  const paymentConsentDialogTitles = {
    title1: "Are you ready to commence the challenge?",
    title2: "Competition Started. Wanna Join ?",
  };

  const [openPaymentConsentDialog, setOpenPaymentConsentDialog] = useState({
    state: false,
    isPracticeMode: false,
    title: paymentConsentDialogTitles.title1,
  });

  const paymentConsentYes = () => {
    setOpenPaymentConsentDialog({
      state: false,
      isPracticeMode: false,
      title: null,
    });

    //setOpenPaymentDialog(true);
    setOpenFreeSubscriptionCheckDialog(true);
  };
  const paymentConsentNo = () => {
    if (openPaymentConsentDialog.isPracticeMode) {
      //RESUME THE GAME LOOP
      try {
        //document.getElementById("gameIframe").contentWindow.EntryPoint.onResumeGame();
        iframeRef.current.contentWindow.EntryPoint.onResumeGame();
      } catch (e) {
        //document.getElementById("gameIframe").src += "";
        console.log("IFRAME ERROR : " + e);
      }
    } else {
      //PROMO GAME -> PRACTICE GAME FROM
      navigate(ImiPathsUrls.home, {
        replace: true,
        state: {
          openPracticeGame: true,
          associatedPromoGameID: uuid,
          promoGameLeaderBoardStatus: PromoGameLeaderBoardStatus.active,
        },
      });
    }

    setOpenPaymentConsentDialog({ state: false, gameMode: null, title: null });
  };

  let paymentConsentDialog = null;

  if (openPaymentConsentDialog.state) {
    paymentConsentDialog = <PaymentConsentDialog title={openPaymentConsentDialog.title} paymentConsentYes={paymentConsentYes} paymentConsentNo={paymentConsentNo} />;
  }

  //#endregion

  //#region  CHECK FOR FREE SUBSCRIPTION DIALOG
  const [openFreeSubscriptionCheckDialog, setOpenFreeSubscriptionCheckDialog] = useState(false);

  const freeSubscriptionCheckDialogCloseFn = (state) => {
    setOpenFreeSubscriptionCheckDialog(false);

    if (state) {
      if (activeGameMode.type === GameModes.PRACTICE) {
        navigate(ImiPathsUrls.home, {
          replace: true,
          state: { gameId: activeGameMode.associatedPromoGameID },
        });
      } else {
        iframeRef.current.src += "";
      }
    } else {
      setOpenPaymentDialog(true);
    }
  };

  let freeSubscriptionCheckDialog = null;

  if (openFreeSubscriptionCheckDialog) {
    freeSubscriptionCheckDialog = <FreeSubscriptionCheckDialog activeGameModeData={activeGameMode} closeFn={freeSubscriptionCheckDialogCloseFn} />;
  }
  //#endregion

  //#region PAYMENT DIALOG
  const [openPaymentDialog, setOpenPaymentDialog] = useState(false);

  const onPaymentDialogActionBtnClick = (redirectUrl = null) => {
    setOpenPaymentDialog(false);

    if (redirectUrl) {
      //document.getElementById("gameIframe").src = redirectUrl;
      window.location.href = redirectUrl;
    } else {
      try {
        //document.getElementById("gameIframe").contentWindow.EntryPoint.onResumeGame();
        iframeRef.current?.contentWindow.EntryPoint.onResumeGame();
      } catch (e) {
        console.log("IFRAME ERROR : " + e);
      }
    }
  };

  let paymentDialog;

  if (openPaymentDialog) {
    paymentDialog = <PaymentDialog activeGameModeData={activeGameMode} onClose={onPaymentDialogActionBtnClick} />;
  } else {
    paymentDialog = null;
  }

  //#endregion

  //#region SHARE DIALOG
  const [openShareDialog, setOpenShareDialog] = useState({
    state: false,
    data: null,
    type: null,
  });

  const [type, setType] = useState("");

  const onCustomShareDialogActionBtnClick = () => {
    setOpenShareDialog({
      state: false,
      data: null,
    });
  };
  let customShareDialog;

  if (openShareDialog.state) {
    if (openShareDialog.type === "GLOBAL") {
      customShareDialog = <GlobalShareDialog data={openShareDialog.data} onClose={onCustomShareDialogActionBtnClick} />;
    } else if (openShareDialog.type === "FRIEND") {
      customShareDialog = <FriendShareDialog data={openShareDialog.data} onClose={onCustomShareDialogActionBtnClick} />;
    }
  }
  //#endregion

  //#region PROMO GAME INTRO SCREEN
  const [openPromoGameIntermediateScreen, setOpenPromoGameIntermediateScreen] = useState(false);

  const onLoadGameScreen = () => {
    setOpenPromoGameIntermediateScreen(false);

    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };

  let promoGameIntermediateScreen = null;

  //IF NOT NORMAL GAME SHOW INTERMEDIATE SCREEN
  if (openPromoGameIntermediateScreen) {
    promoGameIntermediateScreen = <PromoGameIntroScreen loadGameScreen={onLoadGameScreen} gameUUID={uuid} />;
  }
  //#endregion

  //#region SHOW PROMO GAME LIST CONSENT DIALOG
  //THIS IS FOR PRACTICE GAMES

  let promoGameListConsentDialogContent = null;

  const [openPromoGameConsentDialog, setOpenPromoGameConsentDialog] = useState(false);

  const promoGameConsentDialogOnCloseFn = (state) => {
    if (state) {
      setOpenPromoGameListDialog(true);
    } else {
      try {
        //document.getElementById("gameIframe").contentWindow.EntryPoint.onPauseGame();
        iframeRef.current?.contentWindow.EntryPoint.onPauseGame();
      } catch (error) {
        //document.getElementById("gameIframe").src += "";
      }
    }

    setOpenPromoGameConsentDialog(false);
  };

  if (openPromoGameConsentDialog) {
    promoGameListConsentDialogContent = <PromoGameListConsentDialog onCloseFn={promoGameConsentDialogOnCloseFn} />;
  }

  //#endregion

  //#region SHOW PROMO GAME LIST SCREEN
  //THIS IS FOR PRACTICE GAMES
  let promoGameListDialog = null;

  const [openPromoGameListDialog, setOpenPromoGameListDialog] = useState(false);

  const promoGameListScreenCloseFn = () => {
    setOpenPromoGameListDialog(false);
  };

  if (openPromoGameListDialog) {
    promoGameListDialog = <PromoGameList onCloseFn={promoGameListScreenCloseFn} />;
  }

  //#endregion

  //#region GAME OVER YELP DIALOG
  let gameOverYelpDialogScreenContent;

  const [openGameOverYelpDialogScreen, setOpenGameOverYelpDialogScreen] = useState(false);

  const closeGameOverYelpDialogScreen = () => {
    setOpenGameOverYelpDialogScreen(false);
  };

  if (openGameOverYelpDialogScreen) {
    gameOverYelpDialogScreenContent = <GameOverYelpDialog onCloseBtn={closeGameOverYelpDialogScreen} />;
  } else {
    gameOverYelpDialogScreenContent = null;
  }

  //#endregion

  //#region GAME OVER CHALLENGE DIALOG(FREE SUBSCRIPTION)
  let gameOverChallengeDialogContent;

  const [openGameOverChallengeDialog, setOpenGameOverChallengeDialog] = useState(false);

  const closeGameOverChallengeDialog = (status) => {
    if (status) {
      setOpenDuelDialog(true);
    }

    setOpenGameOverChallengeDialog(false);
  };

  if (openGameOverChallengeDialog) {
    gameOverChallengeDialogContent = <GameOverChallengeDialog closeFn={closeGameOverChallengeDialog} />;
  } else {
    gameOverChallengeDialogContent = null;
  }

  //#endregion

  //#region GAME OVER TIMEOUT SCREEN
  let gameOverTimeOutScreenContent;

  const [openGameOverTimeOutScreen, setOpenGameOverTimeOutScreen] = useState(false);

  const leaveGameFn = () => {
    setOpenGameOverTimeOutScreen(false);
    navigate(ImiPathsUrls.home, {
      replace: true,
    });
  };

  if (openGameOverTimeOutScreen) {
    gameOverTimeOutScreenContent = <GameOverTimeOutDialog leaveGameFn={leaveGameFn} />;
  } else {
    gameOverTimeOutScreenContent = null;
  }

  //#endregion

  //#region OPEN FRIEND CHALLENGE DIALOG
  let friendChallengeDialogContent = null;

  const [openFriendChallengeDialog, setOpenFriendChallengeDialog] = useState(false);

  const onCloseOpenFriendChallengeDialog = () => {
    setOpenFriendChallengeDialog(false);
  };

  if (openFriendChallengeDialog) {
    friendChallengeDialogContent = <P2PDuelDialog onCloseFn={onCloseOpenFriendChallengeDialog} />;
  }
  //#endregion

  //#region JOIN CHALLENGE DIALOG
  let joinChallengeContent = null;

  const [openJoinChallengeDialog, setOpenJoinChallengeDialog] = useState({
    state: false,
    data: null,
  });

  const closeJoinChallengeDialogFn = () => {
    setOpenJoinChallengeDialog({ state: false, data: null });
  };

  if (openJoinChallengeDialog.state) {
    joinChallengeContent = <JoinP2PDuelDialog closeFn={closeJoinChallengeDialogFn} challengeCode={openJoinChallengeDialog.data} />;
  }
  //#endregion

  //#region OPEN DUEL DIALOG
  let openDuelDialogContent;

  const [openDuelDialog, setOpenDuelDialog] = useState(false);

  const closeNewChallengeDialogFn = () => {
    setOpenDuelDialog(false);
  };

  if (openDuelDialog) {
    openDuelDialogContent = <CreateNewP2PDuel token={userToken} gameUUID={gameUUID} onCloseFn={closeNewChallengeDialogFn} />;
  } else {
    openDuelDialogContent = null;
  }

  //#endregion

  const isTopHide = isNavBarsHidden ? true : trigger;

  return (
    <Fragment>
      <ImiTopAppBar trigger={!isTopHide} />
      {props.children}
      <ImiBottomNav trigger={!isNavBarsHidden} />

      {logInDialog}
      {otpLoginDialog}

      {paymentConsentDialog}
      {freeSubscriptionCheckDialog}
      {paymentDialog}

      {promoGameIntermediateScreen}
      {gameOverYelpDialogScreenContent}
      {gameOverTimeOutScreenContent}

      {promoGameListConsentDialogContent}
      {promoGameListDialog}

      {friendChallengeDialogContent}
      {joinChallengeContent}
      {gameOverChallengeDialogContent}
      {openDuelDialogContent}
      {openShareDialog.state && customShareDialog}
    </Fragment>
  );
};

export default ImiAppBars;
