import { Button, Divider, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { homeActions } from "../../store";
import { DATA_KEY, ImiPathsUrls, legalContracts } from "../../utils/ImiUtilData";
import { getElementWidth } from "../../utils/ScreenManager";

export default function CommonItemScript({ onAppearanceBtnClick }) {
  //#region STYLES
  const styleConfig = {
    tile: {
      width: "100%",
      height: getElementWidth(140),
      display: "block",
      paddingLeft: getElementWidth(20),
    },
    tileTitle: {
      display: "flex",
      justifyContent: "flex-start",
      textTransform: "none",
      fontSize: getElementWidth(23),
      textAlign: "left",
    },
    tileSubTitle: {
      display: "flex",
      flexDirection: "row",
      textTransform: "none",
      fontSize: getElementWidth(20),
      textAlign: "left",
    },
  };
  //#endregion

  //#region API && HOOKS
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  //#endregion

  const termsCond_BtnClick = () => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: false,
        url: legalContracts.termsCondUrl,
      })
    );

    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };

  const privacyPolicy_BtnClick = () => {
    dispatch(
      homeActions.setActiveUrlData({
        isGame: false,
        url: legalContracts.privacyPolicyUrl,
      })
    );

    navigate(ImiPathsUrls.game, {
      state: {
        from: location,
      },
    });
  };

  return (
    <Fragment>
      <div style={{ height: getElementWidth(60) }}></div>
      {/* <Button onClick={mySubs_BtnClick} style={styleConfig.tile}>
          <Typography color="text.primary" style={styleConfig.tileTitle}>
            My Subscriptions
          </Typography>
          <Typography
            color="text.secondary"
            variant="subtitle2"
            noWrap={true}
            style={styleConfig.tileSubTitle}
          >
            {"Manage Your Subscriptions"}
          </Typography>
        </Button> */}
      <Divider />
      <Button onClick={onAppearanceBtnClick} style={styleConfig.tile}>
        <Typography color="text.primary" style={styleConfig.tileTitle}>
          Appearance
        </Typography>
        <Typography color="text.secondary" variant="subtitle2" noWrap={true} style={styleConfig.tileSubTitle}>
          {"Choose your light or dark theme preference"}
        </Typography>
      </Button>
      <Divider />
      <Button onClick={termsCond_BtnClick} style={styleConfig.tile}>
        <Typography color="text.primary" style={styleConfig.tileTitle}>
          {"Terms & Conditions"}
        </Typography>
        <Typography color="text.secondary" variant="subtitle2" noWrap={true} style={styleConfig.tileSubTitle}>
          {"Check out our terms & conditions"}
        </Typography>
      </Button>
      <Divider />
      <Button onClick={privacyPolicy_BtnClick} style={styleConfig.tile}>
        <Typography color="text.primary" style={styleConfig.tileTitle}>
          Privacy Policy
        </Typography>
        <Typography color="text.secondary" variant="subtitle2" noWrap={true} style={styleConfig.tileSubTitle}>
          {"Check out our privacy policy"}
        </Typography>
      </Button>
      <Divider />

      {/* <Button
        color="primary"
        onClick={signOut_BtnClick}
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: getElementWidth(40),
          marginLeft: "auto",
          marginRight: "auto",
          textTransform: "none",
          fontSize: getElementWidth(20),
        }}
      >
        Sign Out
      </Button> */}
    </Fragment>
  );
}
