import { Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";
import React from "react";
import { getElementHeight, getElementWidth } from "../../utils/ScreenManager";

const DuelListItem = ({ item, commonFnObj, active }) => {
  return (
    <Card
      elevation={0}
      onClick={
        active
          ? () => {
              commonFnObj.openGameFn(item.game_uuid);
            }
          : null
      }
      style={{
        display: "flex",
        width: "100%",
        height: getElementHeight(320),
        marginTop: getElementWidth(40),
        paddingLeft: getElementWidth(25),
        paddingRight: getElementWidth(20.5),
        WebkitFilter: !active ? `grayscale(100%)` : "none",
        filter: !active ? `grayscale(100%)` : "none",
      }}
    >
      <CardMedia
        image={item.game_thumbnail}
        title="image"
        style={{
          width: getElementWidth(250),
          height: "100%",
          borderRadius: 0,
        }}
      />
      <CardContent
        style={{
          backgroundColor: "red",
          height: getElementHeight(320),
          width: getElementWidth(440),
          display: "flex",
          alignItem: "center",
          justifyContent: "center",
          textAlign: "center",
          background: "#FFFFFF",
          flexDirection: "column",
        }}
      >
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "800",
            fontSize: getElementWidth(28),
            color: "rgba(0, 0, 0, 1)",
          }}
        >
          {commonFnObj.getNameDesFn(item.player_one.name)} vs {commonFnObj.getNameDesFn(item.player_two.name)}
        </Typography>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: getElementWidth(25),
            color: "rgba(41, 41, 41, 1)",
          }}
        >
          {active ? "Ends" : "Ended"} on {commonFnObj.getDateDesFn(item.end_time)}
        </Typography>
        <Box sx={{ height: getElementHeight(10), width: "100%" }}></Box>
        <Grid container justifyContent={"center"} flexDirection="row" spacing={2} alignItems={"center"}>
          <Grid item>
            <Box
              sx={{
                width: getElementHeight(80),
                height: getElementHeight(80),
                borderRadius: "50%",
                //backgroundColor: "red",
              }}
            >
              <img style={{ backgroundColor: "transparent", borderRadius: "50%" }} height={getElementHeight(80)} width={getElementHeight(80)} alt="P1" src={item.player_one.image} />
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: getElementHeight(60),
                height: getElementHeight(60),
                //backgroundColor: "red",
              }}
            >
              <img style={{ backgroundColor: "transparent" }} height={"100%"} width={"100%"} alt="giftImg" src="/images/fightImg.png" />
            </Box>
          </Grid>
          <Grid item>
            <Box
              sx={{
                width: getElementHeight(80),
                height: getElementHeight(80),
                borderRadius: "50%",
                //backgroundColor: "red",
              }}
            >
              <img style={{ backgroundColor: "transparent", borderRadius: "50%" }} height={getElementHeight(80)} width={getElementHeight(80)} alt="P2" src={item.player_two.image} />
            </Box>
          </Grid>
        </Grid>
        <Typography
          style={{
            fontFamily: "Roboto",
            fontWeight: "500",
            fontSize: getElementWidth(25),
            color: "rgba(41, 41, 41, 1)",
            paddingTop: getElementHeight(15),
          }}
        >
          {commonFnObj.getScoreDesFn(item.player_one.score, item.player_two.score, active)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DuelListItem;
