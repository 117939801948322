import { Button, Dialog, Typography, Grid, IconButton, Box } from "@mui/material";
import { toBlob } from "html-to-image";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getElementHeight, getElementWidth } from "../../../utils/ScreenManager";
import CancelIcon from "@mui/icons-material/Cancel";
import FeedbackDialog from "../../../utils/FeedbackDialog";
import LoadingDialog from "../../../utils/LoadingDialog";

export default function GlobalShareDialog({ data, onClose }) {
  //#region HOOKS
  //console.log(data);
  const imageRef = useRef(null);
  const urlData = useSelector((state) => state.home.activeUrlData);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ state: false, errorMsg: null });

  const userData = useSelector((state) => state.home.userData);
  const isIos = useSelector((state) => state.home.isIOSDevice);
  const isInFirefoxBrowser = useSelector((state) => state.home.isInFirefox);

  useEffect(() => {
    if (isInFirefoxBrowser) {
      setError({
        state: true,
        errorMsg: "Browser does not support this feature",
      });
      return;
    }

    if (!data.game_logo) {
      setError({ state: true, errorMsg: "Game logo not found" });
      return;
    }
  }, [isInFirefoxBrowser]);
  //#endregion

  //#region UTIL FNS

  const handleShare = async () => {
    const newFile = await toBlob(imageRef.current);

    let shareData;
    if (isIos) {
      shareData = {
        text: `${userData?.name ?? ""} scored a staggering ${data?.score ?? 0} on ${
          data?.game_name ?? ""
        } and challenges you to beat it.\n Are you up to the Challenge? \n ${data?.dynamic_link ?? "https://fiji.imigames.io/home"}`,
      };
    } else {
      shareData = {
        files: [
          new File([newFile], "share.png", {
            type: newFile.type,
          }),
        ],
        title: "share",
        text: `Are you up to the Challenge? \n ${data?.dynamic_link ?? "https://fiji.imigames.io/home"}`,
      };
    }

    try {
      if (!navigator.canShare(shareData)) {
        setError({ state: true, errorMsg: "Something Went Wrong...!" });
        return;
      }
      setLoading(true);
      await navigator
        .share(shareData)
        .then(() => {
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          setError({ state: true, errorMsg: "Something Went Wrong...!" });
        });
    } catch (err) {
      setLoading(false);
      setError({ state: true, errorMsg: "Something Went Wrong...!" });
    }
  };
  //#endregion

  //#region SHARE DIALOG CONTENT
  let topCloseBtnContent = (
    <Box
      sx={{
        paddingRight: "10px",
        height: getElementHeight(150),
        display: "flex",
        justifyContent: "right",
        alignItems: "right",
      }}
    >
      <IconButton
        edge="end"
        color="inherit"
        onClick={() => {
          onClose();
        }}
        aria-label="close"
      >
        <CancelIcon sx={{ fontSize: "2.5rem", color: "white" }} />
      </IconButton>
    </Box>
  );
  let titleContent = (
    <Box
      sx={{
        height: getElementHeight(200),
        display: "flex",
        justifyContent: "center",
        paddingTop: "30px",
      }}
    >
      <Typography variant="h5" sx={{ fontWeight: "700", fontFamily: "Poppins" }}></Typography>
    </Box>
  );
  let shareImageContent = (
    <div
      ref={imageRef}
      style={{
        whiteSpace: "nowrap",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center center",
        height: getElementWidth(720),
        width: getElementWidth(720),
        backgroundImage: `url("/images/globalShare.png")`,
      }}
    >
      <Box
        sx={{
          height: getElementWidth(250),
          //backgroundColor: "red",
          textAlign: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "2px",
          paddingBottom: "2px",
        }}
      >
        <span
          style={{
            fontSize: getElementWidth(36),
            fontWeight: "700",
            fontFamily: "Poppins",
            color: "white",
          }}
        >
          Ready to challenge your brain?
        </span>
        <span
          style={{
            color: "rgba(249,237,50,255)",
            fontSize: getElementWidth(37),
            fontWeight: "700",
            letterSpacing: "0.01em",
            fontFamily: "Poppins",
            lineHeight: "1.05em",
          }}
        >
          Scoring {data?.score ?? 0} points
        </span>
        <div style={{ lineHeight: "1.09em" }}>
          <span
            style={{
              fontSize: getElementWidth(22),
              fontWeight: "700",
              fontFamily: "Poppins",
              color: "white",
            }}
          >
            {userData?.name ?? ""}
          </span>{" "}
          <span
            style={{
              fontSize: getElementWidth(22),
              fontWeight: "700",
              fontFamily: "Poppins",
              color: "white",
            }}
          >
            ranked within the
          </span>
        </div>
        <Typography>
          <span
            style={{
              color: "#FFC736",
              fontSize: getElementWidth(45),
              fontWeight: "700",
              textShadow:
                "-1px -1px 0 rgb(255,255,255),1px 1px 0 rgb(162,128,-93),2px 2px 0 rgb(133,99,-122),4px 4px 3px rgba(0,0,0,0.2),4px 4px 1px rgba(0,0,0,0.5),0px 0px 3px rgba(0,0,0,.2)",
              fontFamily: "Poppins",
              lineHeight: "1.05em",
            }}
          >
            {data?.rank.toUpperCase() ?? ""}
          </span>
        </Typography>
        <div style={{ lineHeight: "1.09em" }}>
          <span
            style={{
              fontSize: getElementWidth(20),
              fontWeight: "700",
              fontFamily: "Poppins",
              lineHeight: "0.01em",
            }}
          >
            {data?.text_data?.top_line ?? ""}
          </span>
        </div>
      </Box>
      <Box
        sx={{
          height: getElementWidth(100),
          //backgroundColor: "green",
        }}
      >
        <Grid display="flex" justifyContent="center" alignItems="center">
          <Grid item>
            <img height={getElementWidth(100)} src={`data:image/png;base64, ${data.game_logo ?? ""}`} alt={data?.game_name ?? ""} />
          </Grid>
          <Grid item>
            <Typography
              style={{
                fontSize: getElementWidth(30),
                color: "rgba(249,237,50,255)",
                fontWeight: "700",
                fontFamily: "Poppins",
              }}
            >
              &nbsp;&nbsp;in&nbsp;&nbsp;
            </Typography>
          </Grid>
          <Grid item>
            <img height={getElementWidth(100)} alt="imi" src="./images/imiLogo.png" />
          </Grid>
        </Grid>
      </Box>
      <Box
        sx={{
          height: getElementWidth(192),
          //backgroundColor: "red",
        }}
      ></Box>
      <Box
        sx={{
          height: getElementWidth(155),
          //backgroundColor: "green",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <div
          style={{
            paddingTop: "5px",
          }}
        >
          <Typography
            style={{
              fontSize: getElementWidth(16),
              textAlign: "left",
              fontWeight: "700",
              fontFamily: "Poppins",
              paddingLeft: "10px",
              color: "white",
            }}
          >
            Reliant on
          </Typography>
          <Typography
            style={{
              letterSpacing: "0.02em",
              fontSize: getElementWidth(47),
              fontWeight: "700",
              fontFamily: "Poppins",
              textShadow: "1.7px 1.7px 0 #dba247",
              color: "white",
            }}
          >
            Cognitive Skills
          </Typography>
          <div style={{ textAlign: "left", paddingLeft: "28px" }}>
            {data?.skills?.length > 0
              ? data.skills.map((text, index) => {
                  return (
                    <Typography
                      key={index}
                      style={{
                        fontSize: getElementWidth(16),
                        fontWeight: "700",
                        fontFamily: "Poppins",
                        color: "white",
                      }}
                    >
                      {index + 1}.{text}
                    </Typography>
                  );
                })
              : null}
          </div>
        </div>
      </Box>
      <Box
        sx={{
          height: getElementWidth(23),
          //backgroundColor: "red",
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
        }}
      >
        <div
          style={{
            width: "100%",
            textAlign: "right",
            paddingRight: "5px",
            //backgroundColor: "red",
          }}
        >
          <Typography
            style={{
              fontSize: getElementWidth(18),
              fontWeight: "700",
              fontFamily: "Poppins",
            }}
          >
            {data?.text_data?.bottom_line ?? "High Score 0"}
          </Typography>
        </div>
      </Box>
    </div>
  );
  let bottomChallengeBtnContent = (
    <Box display="flex" justifyContent={"center"} alignItems={"center"}>
      <Button
        onClick={() => {
          handleShare();
        }}
        style={{
          backgroundColor: "rgba(206,60,55,255)",
          color: "white",
          fontWeight: "700",
          padding: "10px",
          margin: "10px 0px",
          fontFamily: "Poppins",
        }}
      >
        Share
      </Button>
    </Box>
  );
  let shareDialogContent = (
    <>
      {loading && <LoadingDialog />}
      <Dialog
        PaperProps={{
          style: {
            background: "rgba(0,0,0,0.4)",
          },
        }}
        open={true}
        fullScreen
      >
        {topCloseBtnContent}
        {titleContent}
        {shareImageContent}
        {bottomChallengeBtnContent}
        <Box sx={{ height: "auto", backgroundColor: "green" }}></Box>
      </Dialog>
    </>
  );
  //#endregion

  return (
    <Fragment>
      {error.state ? (
        <FeedbackDialog
          open={true}
          onClose={() => {
            onClose();
          }}
          data={{
            title: "Oops!",
            msg: error.errorMsg,
          }}
        ></FeedbackDialog>
      ) : (
        shareDialogContent
      )}
    </Fragment>
  );
}
